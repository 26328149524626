import React from "react";
import {
  SearchResultsGiftCardOutputProps,
  withSearchResultsGiftCardPrice,
} from "./withSearchResultsGiftCardPrice";

export const SearchResultsGiftCardPrice = withSearchResultsGiftCardPrice(
  ({ price, className }: SearchResultsGiftCardOutputProps) => (
    <div className={className}>
      <span>{price}</span>
    </div>
  ),
);
