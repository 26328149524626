import { atom } from "jotai";
import ky, { HTTPError, Options } from "ky";
import { atomWithQuery, atomWithMutation } from "jotai-tanstack-query";

import type { SessionResponse } from "~/api/session";

import type { LogoutResponse } from "~/api/logout";
import type { LoginRequest, LoginResponse } from "~/api/login";
import type { RegisterRequest, RegisterResponse } from "~/api/register";
import type { ActivateRequest, ActivateResponse } from "~/api/activate";

import type {
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "~/api/reset-password";

import type {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
} from "~/api/forgot-password";

/**
 * Response from /api/session endpoint.
 */
export const sessionAtom = atomWithQuery(() => ({
  queryKey: ["session"],
  queryFn: () => ky.get("/api/session").json<SessionResponse>(),
}));

/**
 * Status of session.
 */
export const sessionStatusAtom = atom((get) => {
  const { status } = get(sessionAtom);
  return status;
});

/**
 * Customer from session.
 */
export const sessionCustomerAtom = atom((get) => {
  const { data } = get(sessionAtom);
  return data?.customer ?? null;
});

/**
 * Feature flags from session.
 */
export const sessionFeatureFlagsAtom = atom((get) => {
  const { data } = get(sessionAtom);
  return data?.featureFlags ?? null;
});

/**
 * Session ID from session.
 */
export const sessionIdAtom = atom((get) => {
  const { data } = get(sessionAtom);
  return data?.sessionId ?? null;
});

/**
 * Login a session.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const loginAtom = atomWithMutation(() => ({
  mutationKey: ["login"],
  mutationFn: async (data: LoginRequest) =>
    ky.post("/api/login", { hooks, json: data }).json<LoginResponse>(),
}));

/**
 * Logout the current session.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const logoutAtom = atomWithMutation(() => ({
  mutationKey: ["logout"],
  mutationFn: async () =>
    ky.post("/api/logout", { hooks }).json<LogoutResponse>(),
}));

/**
 * Register a new account.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const registerAtom = atomWithMutation(() => ({
  mutationKey: ["register"],
  mutationFn: async (data: RegisterRequest) =>
    ky.post("/api/register", { hooks, json: data }).json<RegisterResponse>(),
}));

/**
 * Initiate a "forgot password" request.
 */
export const forgotPasswordAtom = atomWithMutation(() => ({
  mutationKey: ["forgotPassword"],
  mutationFn: async (data: ForgotPasswordRequest) =>
    ky
      .post("/api/forgot-password", { hooks, json: data })
      .json<ForgotPasswordResponse>(),
}));

/**
 * Reset password.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const resetPasswordAtom = atomWithMutation(() => ({
  mutationKey: ["resetPassword"],
  mutationFn: async (data: ResetPasswordRequest) =>
    ky
      .post("/api/reset-password", { hooks, json: data })
      .json<ResetPasswordResponse>(),
}));

/**
 * Activate a customer account.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const activateAtom = atomWithMutation(() => ({
  mutationKey: ["activate"],
  mutationFn: async (data: ActivateRequest) =>
    ky.post("/api/activate", { hooks, json: data }).json<ActivateResponse>(),
}));

/**
 * Hooks for ky requests.
 */
const hooks: Options["hooks"] = {
  beforeError: [
    /**
     * Hook for extracting errors from responses.
     * @see https://github.com/sindresorhus/ky#hooksbeforeerror
     */
    async (error: HTTPError) => {
      try {
        const errorJson = await error.response.json();
        if (
          errorJson &&
          "object" === typeof errorJson &&
          "errors" in errorJson &&
          Array.isArray(errorJson.errors)
        ) {
          error.messages = errorJson.errors as string[];
        }
        return error;
      } catch {
        return error;
      }
    },
  ],
};

/**
 * Augment Error to include an optional array of messages.
 * This allows us to include multiple error messages in a single thrown error object.
 */
declare global {
  interface Error {
    messages?: string[];
  }
}
