import { Image } from "@components/Image/Image";
import { Link } from "@components/Link/Link";
import React from "react";
import tw, { styled } from "twin.macro";
import { SearchResultsGiftCardPrice } from "../Results/SearchResultsGiftCardPrice";
import { SearchResultsProductPrice } from "../Results/SearchResultsProductPrice";
import {
  SearchFormResultsProductOutputProps,
  withSearchFormResultsProduct,
} from "./withSearchFormResultsProduct";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";

const Wrapper = styled.div`
  ${tw`flex flex-col items-start w-1/2 mb-micro md:w-1/6 px-mini`}
`;

const InfoWrapper = tw.div`
  w-full md:w-3/4 py-2
`;
const PriceWrapper = tw.div`flex`;
const Title = tw.span`
  inline cursor-pointer
`;
const Text = styled.p`
  ${tw`inline leading-caption`}
  ${({ bold }) => (bold ? tw`font-medium` : null)}
`;

const StyledPrice = styled(SearchResultsProductPrice)`
  ${tw`mt-micro`}
`;

const StyledGiftCardPrice = styled(SearchResultsGiftCardPrice)`
  ${tw`mt-micro`}
`;

const ImageWrapper = tw.div`w-full cursor-pointer mr-mini border border-primary`;

const TextCategory = tw.p`
  font-body text-[13px] text-primary leading-double uppercase tracking-heading
`;

export const SearchFormResultsProduct = withSearchFormResultsProduct(
  ({
    "data-testid": dataTestId,
    category,
    handleClick,
    handleSearchClose,
    image,
    isGiftCard,
    link,
    presentment_price_ranges,
    saleTag,
    subTitle,
    tags,
    title,
    variants,
  }: SearchFormResultsProductOutputProps) => (
    <Wrapper
      data-testid={dataTestId}
      as={link?.url ? Link : null}
      to={link?.url}
      onClick={() => {
        handleClick();
        handleSearchClose();
      }}
    >
      <Image image={image} ratio="11/12" maxWidth={500} critical={true} />
      <InfoWrapper>
        <Title>
          {category && <TextCategory>{category}</TextCategory>}
          <Text bold>{title}</Text>
          <Text>&nbsp;{subTitle}</Text>
        </Title>
        <PriceWrapper>
          {!isGiftCard ? (
            <StyledPrice
              priceRange={presentment_price_ranges}
              variants={variants}
              saleTag={saleTag}
            />
          ) : (
            <StyledGiftCardPrice
              priceRange={presentment_price_ranges}
              tags={tags}
            />
          )}
        </PriceWrapper>
      </InfoWrapper>
    </Wrapper>
  ),
);
