exports.components = {
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-forgot-tsx": () => import("./../../../src/pages/account/forgot.tsx" /* webpackChunkName: "component---src-pages-account-forgot-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../../../src/templates/flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-product-route-tsx": () => import("./../../../src/templates/product/route.tsx" /* webpackChunkName: "component---src-templates-product-route-tsx" */),
  "component---src-templates-returns-portal-tsx": () => import("./../../../src/templates/returns-portal.tsx" /* webpackChunkName: "component---src-templates-returns-portal-tsx" */)
}

