import React from "react";

import { Link } from "gatsby";

import { IconAccount, IconAccountActive } from "@bared/ui";

import { useApp } from "~/hooks/useApp";
import { useHref } from "~/hooks/useHref";
import { useLoggedIn } from "~/hooks/useSession";

const iconClassName = "h-[20px] w-[20px] md:h-[24px] md:w-[24px]";

export function HeaderAccountWidget() {
  const loggedIn = useLoggedIn();
  const accountLink = useHref("/account");

  const { globalStateReducer } = useApp();
  const [, dispatch] = globalStateReducer;

  return (
    <Link
      to={accountLink}
      onClick={() => dispatch({ type: "navigationClicked" })}
      className="transition-presentation duration-slow p-micro text-secondary hover:text-primary md:px-mini md:py-maxi"
    >
      {loggedIn ? (
        <IconAccountActive className={iconClassName} />
      ) : (
        <IconAccount className={iconClassName} />
      )}
    </Link>
  );
}
