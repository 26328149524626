import { useMemo } from "react";

export const useTripleWhale = () =>
  useMemo(() => {
    //@ts-ignore
    const triplePixel =
      typeof window !== "undefined" && window.TriplePixel
        ? window.TriplePixel
        : () => {};

    //@ts-ignore
    const isDev =
      process.env.GATSBY_ACTIVE_ENV === "development" ||
      process.env.NODE_ENV === "development";

    const trackAddToCart = (item: string, q: number) => {
      if (isDev) console.log(`[TRIPLEWHALE] add to cart fired.`);
      triplePixel("AddToCart", { item, q });
    };

    const trackVisitorContact = (email: string) => {
      if (isDev) console.log(`[TRIPLEWHALE] visitor contact fired.`);
      triplePixel("Contact", { email });
    };

    const trackPageLoad = (type: string) => {
      if (isDev) console.log(`[TRIPLEWHALE] page load fired.`);
      triplePixel("PageLoad", { type });
    };

    return { trackAddToCart, trackVisitorContact, trackPageLoad };
  }, []);
