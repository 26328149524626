import React from "react";

import { TrackingProvider } from "./tracking";
import { WishlistProvider } from "./wishlist";

import { UIProvider } from "./UIProvider";

export interface LocalisedProvidersProps {
  children: React.ReactNode;
}

export const LocalisedProviders: React.FC<LocalisedProvidersProps> = ({
  children,
}) => {
  return (
    <WishlistProvider>
      <TrackingProvider>
        <UIProvider>{children}</UIProvider>
      </TrackingProvider>
    </WishlistProvider>
  );
};
