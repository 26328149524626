import { useCurrency } from "@hooks/useCurrency";
import { ComponentProps } from "@ts/components";
import React, { FC } from "react";
import { MoneyV2 } from "@ts/shopify-storefront";

type CartLineItemPriceInputProps = ComponentProps & {
  price: MoneyV2;
  compareAtPrice?: MoneyV2;
  discountAllocationsAmount: number;
  layout?: "cart" | "product";
};

type CartLineItemPriceOutputProps = Omit<
  CartLineItemPriceInputProps,
  "discountAllocationsAmount" | "price" | "compareAtPrice"
> & {
  price: string;
  compareAtPrice: string;
  onSale?: boolean;
  currencyCode?: string;
  priceAfterDiscount?: string;
};

export const withCartLineItemPrice =
  (Component: FC<CartLineItemPriceOutputProps>) =>
  ({
    name = "CartLineItemPrice",
    price,
    compareAtPrice,
    discountAllocationsAmount,
    className,
    layout,
  }: CartLineItemPriceInputProps) => {
    const { formatCurrency } = useCurrency();

    const { amount: priceAmount, currencyCode } = price;
    const { amount: compareAtPriceAmount } = compareAtPrice || {};

    const onSale =
      discountAllocationsAmount > 0 ||
      Number(compareAtPriceAmount) > Number(priceAmount);
    const formattedPrice = priceAmount
      ? formatCurrency(Number(priceAmount), currencyCode, 0)
      : "";
    const formattedCompareAtPrice = compareAtPriceAmount
      ? formatCurrency(Number(compareAtPriceAmount), currencyCode, 0)
      : "";
    const priceAfterDiscount = (
      priceAmount - discountAllocationsAmount
    ).toFixed(2);

    Component.displayName = name;

    return (
      <Component
        price={formattedPrice}
        compareAtPrice={formattedCompareAtPrice}
        priceAfterDiscount={priceAfterDiscount}
        onSale={onSale}
        currencyCode={currencyCode}
        className={className}
        layout={layout}
      />
    );
  };
