import { ComponentProps } from "@ts/components";
import React, { FC, memo } from "react";

export type NavigationHeaderInputProps = ComponentProps & {
  navigationRootPath: string;
};

export type NavigationHeaderOutputProps = NavigationHeaderInputProps;

export const withNavigationHeader = (
  Component: FC<NavigationHeaderOutputProps>,
) =>
  memo(
    ({ name = "NavigationHeader", ...props }: NavigationHeaderInputProps) => {
      Component.displayName = name;
      return <Component {...props} />;
    },
  );
