import { ResolvedLink } from "../types/components";
import { useApp } from "./useApp";
import { useCore } from "./useCore";
import { useLocalisation } from "./useLocalisation";

export const useHelper = () => {
  const { navigate } = useLocalisation();
  const combineArrays = (a, b) => {
    let tempArray = [];
    if (a && a.length > 0) {
      tempArray.push(a);
    }
    if (b && b.length > 0) {
      tempArray.push(b);
    }
    return Array.from(new Set([].concat(...tempArray)));
  };

  const removeDuplicateObjectInArray = <T>(arr: T[], keyName: string) => {
    const keyMap: Record<string, boolean> = {};

    return arr.reduce((result: T[], current: T) => {
      const currentKey = current[keyName];
      if (keyMap[currentKey]) {
        return result;
      }

      keyMap[currentKey] = true;

      return [...result, current];
    }, []);
  };

  // from lodash.unescape https://github.com/lodash/lodash/blob/master/unescape.js
  // add &#x27;
  const unescape = (string) => {
    const htmlUnescapes = {
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#39;": "'",
      "&#x27;": "'",
    };
    const reEscapedHtml = /&(?:amp|#x27|lt|gt|quot|#(0+)?39);/g;
    const reHasEscapedHtml = RegExp(reEscapedHtml.source);

    return string && reHasEscapedHtml.test(string)
      ? string.replace(reEscapedHtml, (entity) => htmlUnescapes[entity] || "'")
      : string || "";
  };

  const position = () => {
    //@ts-ignore
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  };

  const inOutQuintic = (t: number, b: number, c: number, d: number) => {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
  };

  const move = (amount: number) => {
    const body =
      document.documentElement || document.body.parentNode || document.body;
    // @ts-ignore
    body.scrollTop = amount;
  };

  const scrollTo = (to: number, callback = () => {}, duration = 500) => {
    const start = position();
    const change = to - start;
    const increment = 20;

    let currentTime = 0;
    duration = typeof duration === "undefined" ? 500 : duration;

    if (duration === 0) {
      return move(to);
    }

    const animateScroll = function () {
      // increment the time
      currentTime += increment;
      // find the value with the quadratic in-out easing function
      const val = inOutQuintic(currentTime, start, change, duration);
      // move the document.body
      move(val);
      // do the animation unless its over
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else if (callback && typeof callback === "function") {
        // the animation is done so lets callback
        callback();
      }
    };
    animateScroll();
  };

  const getFormattedDate = (dateString: string): string => {
    if (!dateString) {
      return "Unknown";
    }

    const monthsMapping = [
      `Jan`,
      `Feb`,
      `Mar`,
      `Apr`,
      `May`,
      `Jun`,
      `Jul`,
      `Aug`,
      `Sep`,
      `Oct`,
      `Nov`,
      `Dec`,
    ];
    const date = new Date(dateString);
    if (!date) {
      return "Unknown";
    }

    let day = `${date.getDate()}`;
    day = day.length < 2 ? `0${day}` : day;
    const month = monthsMapping[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const shortDateToISOString = (date: string) => {
    const monthsMapping = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const parts = date?.split("/");

    if (parts?.length !== 3) {
      return;
    }

    const day = parseInt(`${parts[0]}`, 10);
    const month = monthsMapping.indexOf(`${parts[1]}`);
    const year = parseInt(`${parts[2]}`, 10);

    if (month < 0 || month > 12) {
      return;
    }

    const parsedDate = new Date(year, month, day);

    if (!(parsedDate instanceof Date)) {
      return;
    }

    return parsedDate.toISOString();
  };

  const isValidDate = (date) => {
    const monthsMapping = [
      `Jan`,
      `Feb`,
      `Mar`,
      `Apr`,
      `May`,
      `Jun`,
      `Jul`,
      `Aug`,
      `Sep`,
      `Oct`,
      `Nov`,
      `Dec`,
    ];
    const parts = date.split("/");
    const day = parseInt(parts[0], 10);
    const month = monthsMapping.indexOf(parts[1]) + 1;
    const year = parseInt(parts[2], 10);

    if (month == 0 || month > 12) {
      return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }

    return day > 0 && day <= monthLength[month - 1];
  };

  const isFutureDate = (date) => {
    const monthsMapping = [
      `Jan`,
      `Feb`,
      `Mar`,
      `Apr`,
      `May`,
      `Jun`,
      `Jul`,
      `Aug`,
      `Sep`,
      `Oct`,
      `Nov`,
      `Dec`,
    ];

    const parts = date.split("/");

    const day = parseInt(parts[0], 10);
    const formattedDay = day < 10 ? `0${day}` : day;

    const month = monthsMapping.indexOf(parts[1]) + 1;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const year = parts[2];

    const now = new Date();

    const nowDay = now.getDate();
    const formattedNowDay = nowDay < 10 ? `0${nowDay}` : nowDay;
    const nowMonth = now.getMonth() + 1;
    const formattedNowMonth = nowMonth < 10 ? `0${nowMonth}` : nowMonth;

    const nowYear = now.getFullYear();

    const nowInt = parseInt(
      `${nowYear}${formattedNowMonth}${formattedNowDay}`,
      10,
    );
    const dateInt = parseInt(`${year}${formattedMonth}${formattedDay}`, 10);
    return nowInt <= dateInt;
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  };

  const {
    config: {
      app: { url },
    },
  } = useApp();

  const {
    helpers: { encodeBase64 },
  } = useCore();

  const getShareWishlistURL = (email, name, internal = false) => {
    const params = encodeBase64(JSON.stringify({ email, name }));
    const sharedUrl = !internal
      ? `${url}/wishlist?share=${params}`
      : `/wishlist?share=${params}`;
    return sharedUrl;
  };

  const handleClickOnCustomContentLink = (link?: ResolvedLink) => {
    if (link?.url && link?.external) {
      window.open(link?.url, "_blank");
    } else if (link?.url) {
      window.location.href = link?.url;
    } else {
      navigate(link?.url, { replace: false });
    }
  };

  return {
    combineArrays,
    getFormattedDate,
    getShareWishlistURL,
    handleClickOnCustomContentLink,
    isFutureDate,
    isJson,
    isValidDate,
    position,
    removeDuplicateObjectInArray,
    shortDateToISOString,
    scrollTo,
    unescape,
  };
};
