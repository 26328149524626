/**
 * @typedef {import("./src/providers/global/config.js").Config} Config
 */

/** @type {import("type-fest").PartialDeep<Config>} */
export default {
  app: {
    url: "https://baredfootwear.com",
  },
  services: {
    functions: {
      endpoint: "https://api.bared.com.au",
    },
  },
};
