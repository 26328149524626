import { useSelector, type DropdownOption } from "@hooks/useSelector";
import { ComponentProps, NormalisedProductVariant } from "@ts/components";
import { MoneyV2, Product } from "@ts/shopify-storefront";
import React, { FC, useState } from "react";

export type CartUpsellsCardInputProps = ComponentProps & {
  product: Product;
  isLast?: boolean;
};

export type CartUpsellsCardOutputProps = Omit<
  CartUpsellsCardInputProps,
  "product"
> & {
  compareAtPrice?: MoneyV2;
  image?: string;
  link?: string;
  options?: DropdownOption[];
  price?: MoneyV2;
  quantity: number;
  selectedVariant: NormalisedProductVariant;
  singleVariant?: boolean;
  subtitle?: string;
  title?: string;
  tags?: Array<string>;
  onUpdateQuantity: (qty: number) => () => void;
  onChangeVariant: (value: string) => void;
};

export const withCartUpsellsCard =
  (Component: FC<CartUpsellsCardOutputProps>) =>
  ({ name = "CartUpsellsCard", product, isLast }): JSX.Element => {
    const { link, singleVariant, variants, title, images, tags } =
      product || {};
    const [quantity, setQuantity] = useState(1);
    const { dropdownOptions, selectedVariant, handleOptionChange } =
      useSelector({ variants });
    const { priceV2, compareAtPriceV2 } = variants?.[0] || selectedVariant;
    const productTitle = title?.split(" ")?.[0] || title;
    const subtitle = title?.replace(productTitle, "");
    const image = images?.[0]?.src;

    const handleUpdateQuantity = (qty: number) => () => {
      setQuantity((prevState) => prevState + qty);
    };

    Component.displayName = name;

    return (
      <Component
        compareAtPrice={compareAtPriceV2}
        image={image}
        link={link}
        onChangeVariant={handleOptionChange}
        onUpdateQuantity={handleUpdateQuantity}
        options={dropdownOptions}
        price={priceV2}
        tags={tags}
        quantity={quantity}
        selectedVariant={selectedVariant}
        singleVariant={singleVariant}
        subtitle={subtitle}
        title={productTitle}
        isLast={isLast}
      />
    );
  };
