import { useLocalisationContext } from "@hooks/useLocalisation";
import { type ComponentProps } from "@ts/components";
import React, { type FC } from "react";

type CurrencyInputProps = ComponentProps;

type CurrencyOutputProps = {
  activeCurrency?: string;
};

export const withCurrency =
  (Component: FC<CurrencyOutputProps>) =>
  ({ name = "Currency" }: CurrencyInputProps) => {
    const { currentLocale } = useLocalisationContext();
    const activeCurrency = currentLocale?.defaultCurrency;

    Component.displayName = name;

    return <Component activeCurrency={activeCurrency} />;
  };
