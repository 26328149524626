import React, { useMemo, useCallback, FC } from "react";

import { useApp } from "@hooks/useApp";
import { useWishlistContext } from "@hooks/useWishlist";
import { ComponentProps } from "@ts/components";

import { useLoggedIn } from "~/hooks/useSession";

export type WishlistWidgetInputProps = ComponentProps;

export type WishlistWidgetOutputProps = WishlistWidgetInputProps & {
  wishlistUrl: string;
  count: number;
  handleClick: () => void;
};

export const withWishlistWidget = (Component: FC<WishlistWidgetOutputProps>) =>
  React.memo(({ name = "WishlistWidget" }: WishlistWidgetInputProps) => {
    const {
      config: {
        settings: { routes },
      },
      globalStateReducer,
    } = useApp();

    const { count } = useWishlistContext();

    const loggedIn = useLoggedIn();

    const [, dispatch] = globalStateReducer;

    const handleClick = useCallback(() => {
      dispatch({ type: "navigationClicked" });
    }, [dispatch]);

    const wishlistUrl = loggedIn ? routes?.WISHLIST : routes?.SAVED;

    Component.displayName = name;
    return useMemo(
      () => (
        <Component
          wishlistUrl={wishlistUrl}
          count={count}
          handleClick={handleClick}
        />
      ),
      [wishlistUrl, count, handleClick],
    );
  });
