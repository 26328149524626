import { useCallback } from "react";
import { useFirebase } from "./useFirebase";
import { useLocalisation } from "./useLocalisation";
import { useSettings } from "./useSettings";

import { useCustomer } from "./useSession";

type Sale = {
  type: string;
  enabled: boolean;
  locales?: string[];
};

export const useSale = () => {
  const customer = useCustomer();
  const { currentLocale } = useLocalisation();
  const { sale: staticSale } = useSettings();
  const { sale: liveSale } = useFirebase();
  const sale = liveSale ?? (staticSale as Sale);

  const isSaleEnabled = useCallback(() => {
    return sale.enabled;
  }, [sale]);

  const isPublicOrCustomerSpecificSale = useCallback(() => {
    if ("sale" === sale.type) {
      return true;
    } else if ("vip" === sale.type) {
      return !!customer?.tags?.includes("loyalty:VIP");
    } else if ("test" === sale.type) {
      return !!customer?.tags?.includes("loyalty:TEST");
    } else if ("vip-test" === sale.type) {
      return (
        !!customer?.tags?.includes("loyalty:TEST") &&
        !!customer?.tags?.includes("loyalty:VIP")
      );
    }

    return false;
  }, [sale, customer]);

  const isGlobalOrLocaleSpecificSale = useCallback(() => {
    // If locale field is empty or missing, we assume a global sale
    if (!sale.locales || sale.locales.length === 0) {
      return true;
    }

    if (!currentLocale) {
      return false;
    }

    return sale.locales.includes(currentLocale.defaultCountryCode);
  }, [currentLocale, sale]);

  const isSaleActive = useCallback(() => {
    if (!isSaleEnabled()) {
      return false;
    }

    return isPublicOrCustomerSpecificSale() && isGlobalOrLocaleSpecificSale();
  }, [
    isSaleEnabled,
    isPublicOrCustomerSpecificSale,
    isGlobalOrLocaleSpecificSale,
  ]);

  return { sale, isSaleActive };
};
