import { useApp } from "@hooks/useApp";
import { useLocalisationContext } from "@hooks/useLocalisation";
import { useScroll } from "@hooks/useScroll";
import { HistoryLocation } from "@reach/router";
import { ComponentProps } from "@ts/components";
import React, { FC, memo, useEffect, useMemo } from "react";

export type HeaderInputProps = ComponentProps & {
  location: HistoryLocation;
  navigationRootPath: string;
};

export type HeaderOutputProps = HeaderInputProps & {
  activeSearch: boolean;
  handleClick: () => void;
  handleRedirect: (type: "redirect" | "search", url: string) => void;
  headerRef: React.MutableRefObject<HTMLElement>;
  topBarRef: React.MutableRefObject<HTMLElement>;
};

export const withHeader = (Component: FC<HeaderOutputProps>) =>
  memo(
    ({ name = "Header", location, navigationRootPath }: HeaderInputProps) => {
      const { globalStateReducer } = useApp();
      const { navigate } = useLocalisationContext();
      const { headerRef, topBarRef } = useScroll();
      const [{ activeSearch }, dispatch] = globalStateReducer;

      const handleClick = () => {
        toggleSearch();
      };

      const toggleSearch = () => {
        dispatch({
          type: "setActiveSearch",
          payload: !activeSearch,
        });
      };

      const handleRedirect = (type: "redirect" | "search", url: string) => {
        toggleSearch();
        navigate(url, { type });
      };

      useEffect(() => {
        const searchInput = document.querySelector<HTMLInputElement>(
          `input[aria-label="DesktopSearchForm"]`,
        );
        if (activeSearch && searchInput) {
          searchInput.select();
        }
      }, [activeSearch]);

      Component.displayName = name;

      return useMemo(
        () => (
          <Component
            activeSearch={activeSearch}
            handleClick={handleClick}
            handleRedirect={handleRedirect}
            location={location}
            headerRef={headerRef}
            topBarRef={topBarRef}
            navigationRootPath={navigationRootPath}
          />
        ),
        [
          activeSearch,
          handleClick,
          handleRedirect,
          location,
          navigationRootPath,
        ],
      );
    },
  );
