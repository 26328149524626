import { Icon } from "@components/Icon";
import { Link } from "@components/Link/Link";
import React from "react";
import tw, { styled } from "twin.macro";
import {
  WishlistWidgetOutputProps,
  withWishlistWidget,
} from "./withWishlistWidget";

const StyledWishlist = styled(Link)`
  ${tw`relative flex mt-nano`}
`;

const StyledIcon = tw(Icon)`
  p-micro text-secondary hover:text-primary transition-presentation duration-slow md:px-mini md:py-maxi
`;

const Quantity = styled.p`
  ${tw`absolute font-bold text-white text-caption top-1/2 left-1/2 transition-layout pt-nano pb-pico`}
  transform: translate(-50%, -50%);
`;

export const WishlistWidget = withWishlistWidget(
  ({ handleClick, wishlistUrl, count }: WishlistWidgetOutputProps) => (
    <StyledWishlist onClick={handleClick} to={wishlistUrl}>
      <StyledIcon name={count > 0 ? "wishlisted" : "wishlist"} size="xsmall" />
      <Quantity>{count || "0"}</Quantity>
    </StyledWishlist>
  ),
);
