import { type NormalisedNavigation } from "@hooks/useNavigation";
import { type ComponentProps } from "@ts/components";
import { type ResponsiveImages } from "@ts/sanity";
import {
  useMenu,
  useMenuState,
  type MenuProps,
  type MenuState,
} from "ariakit/menu";
import React, { memo, useEffect, type FC, useMemo } from "react";

export type NavigationHeaderSubInputProps = ComponentProps & {
  active: boolean;
  handleHover: (value: string) => () => void;
  subNavigation?: NormalisedNavigation;
};

export type NavigationHeaderSubOutputProps = Omit<
  NavigationHeaderSubInputProps,
  "handleHover"
> & {
  collectionTitle?: string;
  contents?: NormalisedNavigation[];
  dataTestId?: string;
  desktopImages?: ResponsiveImages[];
  featuredTitle?: string;
  handleHover: (value: string) => () => void;
  items?: NormalisedNavigation[];
  menuProps: Partial<MenuProps>;
  menuState: MenuState;
  shopEditItems?: NormalisedNavigation[];
  shopEditTitle?: string;
};

export const withNavigationHeaderSub = (
  Component: FC<NavigationHeaderSubOutputProps>,
) =>
  memo(
    ({
      name = "NavigationHeaderSub",
      subNavigation,
      active,
      handleHover,
      "data-testid": dataTestId,
    }: NavigationHeaderSubInputProps) => {
      const desktopImages = subNavigation?.images?.filter(
        (item) => item?.desktopImage,
      );
      const menuState = useMenuState();
      // Despite the fact that this variable is unused, it needs to be called to manage
      // the state of the menu
      const menuProps = useMenu({ state: menuState });
      // We don't use style and wrapElement because of unwanted styling behaviour from the ariakit library
      const { style, wrapElement, ...rest } = menuProps;
      const { setOpen, open } = menuState;

      useEffect(() => {
        setOpen(active);
      }, [active]);

      Component.displayName = name;

      return useMemo(
        () => (
          <Component
            active={open}
            collectionTitle={subNavigation?.collectionTitle}
            contents={subNavigation?.contents}
            dataTestId={dataTestId}
            desktopImages={desktopImages}
            featuredTitle={subNavigation?.featuredTitle}
            handleHover={handleHover}
            items={subNavigation?.items}
            menuState={menuState}
            menuProps={rest}
            shopEditItems={subNavigation?.shopEditItems}
            shopEditTitle={subNavigation?.shopEditTitle}
            title={subNavigation?.title}
          />
        ),
        [
          dataTestId,
          open,
          subNavigation,
          desktopImages,
          handleHover,
          menuState,
          rest,
        ],
      );
    },
  );
