import { Icon } from "@components/Icon";
import { Image } from "@components/Image/Image";
import { Link } from "@components/Link/Link";
import Money from "@components/Money/Money";
import { ImageWrapper as StyledImageWrapper } from "@components/Styled/ImageWrapper";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import React from "react";
import tw from "twin.macro";
import { withCartLineItemAccessoryCard } from "./withCartLineItemAccessoryCard";

const Wrapper = styledWithInvalidProps("div")(({ layout, loading }) => [
  tw`relative flex flex-wrap items-center w-full border-b bg-white border-tertiary last-of-type:border-none`,
  layout === `cart` ? tw`pr-midi` : tw`pr-mini text-[13px]`,
  loading ? tw`opacity-50` : null,
]);
const ImageWrapper = tw(StyledImageWrapper)`
  w-giga h-giga
`;
const Price = tw(Money)`
  font-medium
`;
const Content = tw.div`
  flex flex-1 justify-between p-mini
`;
const ProductLink = tw(Link)`
  mx-pico underline-offset-4 underline
`;
const Label = tw.div`
  block
`;
const Remove = tw(Icon)`
  cursor-pointer -mr-pico
`;

export const CartLineItemAccessoryCard = withCartLineItemAccessoryCard(
  ({
    "data-testid": dataTestId,
    image,
    layout,
    loading,
    handleLineItemRemove,
    link,
    prefix,
    price,
    suffix,
    title,
  }) => (
    <Wrapper data-testid={dataTestId} loading={loading} layout={layout}>
      <ImageWrapper layout={layout} as={link ? Link : null} to={link}>
        <Image
          alt={title}
          image={image?.url}
          ratio="1/1"
          maxWidth={150}
          useSrcSet={false}
        />
      </ImageWrapper>
      <Content>
        <Label>
          {prefix}
          {link ? <ProductLink to={link}>{title}</ProductLink> : title}
          {suffix}
        </Label>
        <Price price={price} displayZeroAsFree={true} />
      </Content>
      <Remove
        data-testid="cart-line-item-accessory-card-remove"
        name="close"
        size="xsmall"
        onClick={handleLineItemRemove}
      />
    </Wrapper>
  ),
);
