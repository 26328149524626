import { Icon } from "@components/Icon";
import React from "react";
import tw from "twin.macro";
import {
  CartReturnsPolicyOutputProps,
  withCartReturnsPolicy,
} from "./withCartReturnsPolicy";

const ReturnPolicy = tw.aside`
  p-midi bg-neutral-alt bg-opacity-20 rounded-micro text-warning mb-midi flex flex-row items-start
`;

const IconContainer = tw.span`
  pt-pico
`;
const ReturnPolicyText = tw.p`
  ml-midi text-hint leading-caption
`;

export const CartReturnsPolicy = withCartReturnsPolicy(
  ({
    hasSaleItems,
    hasFinalSaleItems,
    saleItemReturnPolicy,
    finalSaleItemReturnPolicy,
  }: CartReturnsPolicyOutputProps) => (
    <ReturnPolicy>
      <IconContainer>
        <Icon name="warning" size="xsmall" />
      </IconContainer>
      {hasSaleItems || hasFinalSaleItems ? (
        <ReturnPolicyText>
          {hasFinalSaleItems ? finalSaleItemReturnPolicy : saleItemReturnPolicy}
        </ReturnPolicyText>
      ) : null}
    </ReturnPolicy>
  ),
);
