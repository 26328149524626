import React from "react";
import tw, { styled } from "twin.macro";
import { ComponentProps } from "../../types/components";

/** @todo Change these keys to reflect new names e.g. primary, secondary */
const styles = {
  colour: {
    default: tw``,
    beige: tw`text-tertiary`,
    light: tw`text-neutral h-maxi bg-neutral`,
    white: tw`text-white`,
    charcoal: tw`text-primary`,
  },
};

export type ContentDividerProps = ComponentProps & {
  colour?: keyof typeof styles;
};

const Wrapper = tw.div`w-full`;

const Divider = styled.hr`
  ${({ colour }) => styles.colour[colour]}
`;

export const ContentDivider = ({ className, colour = "default" }) => (
  <Wrapper className={className}>
    <Divider colour={colour}></Divider>
  </Wrapper>
);
