import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import React from "react";
import tw from "twin.macro";
import { withSearchResultsProductPrice } from "./withSearchResultsProductPrice";

const Wrapper = tw.div`
  flex flex-col justify-end
`;
const OriginalMoney = tw.span`
  mr-pico
`;
const Money = styledWithInvalidProps("span")(({ sale }) => [
  sale ? tw`font-medium text-primary` : null,
]);

export const SearchResultsProductPrice = withSearchResultsProductPrice(
  ({ price, salePrice, className }) => (
    <div className={className}>
      {salePrice ? (
        <Wrapper>
          <OriginalMoney>
            <s>{price}</s>
          </OriginalMoney>
          <Money sale={true}>{salePrice}</Money>
        </Wrapper>
      ) : (
        <Money>{price}</Money>
      )}
    </div>
  ),
);
