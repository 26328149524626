import { Buffer } from "buffer";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { ComponentProps } from "../../types/components";

export type AccordionInputProps = ComponentProps & {
  active?: boolean;
  handleRedirect?: () => void;
  icon?: boolean;
  link?: string;
  onClick?: () => void;
  size?:
    | "header"
    | "footer"
    | "account"
    | "accountNavigation"
    | "filter"
    | "product"
    | "blog"
    | "minicart"
    | "pdp";
  title?: string;
  hightlight?: boolean;
};

export type AccordionOutputProps = AccordionInputProps & {
  handleActive: () => void;
  hash: string;
  setActive: Dispatch<SetStateAction<boolean>>;
};

export const withAccordion =
  (Component: FC<AccordionOutputProps>) =>
  ({
    name = "Accordion",
    active: externalActive = false,
    onClick,
    title,
    handleRedirect,
    hightlight,
    ...props
  }: AccordionInputProps) => {
    const [active, setActive] = useState(externalActive);
    const handleActive = () => (onClick ? onClick() : setActive(!active));
    const hash = Buffer.from(
      JSON.stringify({
        externalActive,
        title,
      }),
    ).toString("base64");

    Component.displayName = name;

    return (
      <Component
        {...props}
        active={active}
        hightlight={hightlight}
        handleActive={handleActive}
        handleRedirect={handleRedirect}
        onClick={onClick}
        setActive={setActive}
        title={title}
        hash={hash}
      />
    );
  };
