import React from "react";
import tw from "twin.macro";
import { withCurrency } from "./withCurrency";

const Wrapper = tw.div`
  mx-midi md:mx-maxi
`;

export const Currency = withCurrency(({ activeCurrency }) => {
  return <Wrapper>{activeCurrency}</Wrapper>;
});
