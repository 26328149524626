import React, { useMemo, useCallback, memo } from "react";
import { useApp } from "../../../hooks/useApp";
import { useCheckout } from "../../../hooks/useCheckout";

export const withCartWidget = (Component) =>
  memo(({ name = "CartTotal" }) => {
    const { globalStateReducer } = useApp();
    const { calculateLineItemsQuantityTotal } = useCheckout();
    const [{ activeCart }, dispatch] = globalStateReducer;

    const handleClick = useCallback(() => {
      dispatch({
        type: "setActiveCart",
        payload: !activeCart
          ? { activeCart: !activeCart, newItemAdded: false }
          : false,
      });
    }, [activeCart, dispatch]);

    const quantity = calculateLineItemsQuantityTotal();

    Component.displayName = name;
    return useMemo(
      () => <Component quantity={quantity} handleClick={handleClick} />,
      [quantity, handleClick],
    );
  });
