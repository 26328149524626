import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const FirebaseContext = createContext({
  firebase: null,
  app: null,
  user: null,
  providers: null,
  db: null,
  functions: null,
  sale: null,
});

export const useFirebaseContext = () => {
  const data: any = useContext(FirebaseContext);
  return { ...data };
};

export const FirebaseProvider = ({ children, config }) => {
  const [firebase, setFirebase] = useState<typeof firebase>(null);
  const [firebaseApp, setFirebaseApp] = useState(null);
  const [firebaseDB, setFirebaseDB] = useState(null);
  const [firebaseFunctions, setFirebaseFunctions] = useState(null);
  const [sale, setSale] = useState(null);

  const initFirebase = async () => {
    const development = process.env["NODE_ENV"] === "development";
    const { default: firebaseLib } = await import("firebase/app");
    await Promise.all([
      import("firebase/firestore"),
      import("firebase/functions"),
    ]);

    const app =
      firebaseLib.apps?.[0] ??
      firebaseLib.initializeApp({
        apiKey: config.apiKey,
        authDomain: config.authDomain,
        databaseURL: config.databaseURL,
        projectId: config.projectId,
      });
    setFirebase(firebaseLib);
    setFirebaseApp(app);

    if (firebaseLib.functions) {
      const functions = firebaseLib.functions();
      setFirebaseFunctions(functions);
      if (development) firebaseLib.functions().useEmulator("localhost", 5001);
    }

    if (firebaseLib.firestore) {
      const db = firebaseLib.firestore();
      setFirebaseDB(db);
    }
  };

  useEffect(() => {
    initFirebase();
  }, []);

  useEffect(() => {
    if (!firebaseDB) {
      return;
    }
    firebaseDB
      .collection("settings")
      .doc(process.env["GATSBY_FIREBASE_SALE_DOCUMENT"] || "sale-dev")
      .onSnapshot((doc) => setSale(doc.data()));
  }, [firebaseDB]);

  const values = useMemo(
    () => ({
      firebase,
      app: firebaseApp,
      db: firebaseDB,
      functions: firebaseFunctions,
      sale,
    }),
    [firebase, firebaseApp, firebaseDB, firebaseFunctions, sale],
  );

  return (
    <FirebaseContext.Provider value={values}>
      {children}
    </FirebaseContext.Provider>
  );
};
