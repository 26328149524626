import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { StyledInput } from "@components/Styled/FormElements";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import { ComponentProps } from "@ts/components";
import React, { ChangeEvent } from "react";
import tw from "twin.macro";
import FormLabel from "./FormLabel";

export type FormInputProps = ComponentProps & {
  className?: string;
  color?: string;
  defaultValue?: string;
  error?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInvalid?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  maxLength?: number;
  name: string;
  optional?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  required?: boolean;
  requiredFields?: {
    [key: string]: boolean;
  };
  type?: string;
  value?: string | boolean;
  width?: string;
};

const Wrapper = styledWithInvalidProps("div")(({ width }) => [
  tw`w-full h-full px-mini`,
  width === `1/2` ? tw`md:w-1/2` : null,
]);

const Input = withFocusRing(
  styledWithInvalidProps(StyledInput)(({ error, color, requiredError }) => [
    error ? tw`text-warning` : null,
    color === "light" ? tw`text-white border-white` : tw`text-primary`,
    requiredError ? tw`border-b-2 text-warning border-warning` : null,
  ]),
);

const ErrorMessage = tw.p`
  text-caption mt-micro text-warning
`;

const FormInput = ({
  className,
  color,
  defaultValue,
  error,
  handleChange,
  handleInvalid,
  id,
  label,
  maxLength,
  name,
  optional,
  placeholder,
  placeholderColor,
  readOnly,
  required,
  requiredFields,
  type,
  value,
  width,
  "data-testid": dataTestId,
}: FormInputProps) => {
  return (
    <Wrapper width={width} className={className}>
      <FormLabel
        label={label}
        id={id}
        required={required}
        optional={optional}
        requiredError={requiredFields?.[id]}
      />
      <Input
        color={color}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        error={error}
        requiredError={requiredFields?.[id]}
        id={id}
        maxLength={maxLength}
        name={name}
        onChange={handleChange}
        onInvalid={handleInvalid}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        readOnly={readOnly}
        required={required}
        aria-invalid={requiredFields?.[id]}
        aria-describedby={id}
        type={type}
        value={value}
      />
      {requiredFields?.[id] && (
        <ErrorMessage>This field is required.</ErrorMessage>
      )}
    </Wrapper>
  );
};
export default FormInput;
