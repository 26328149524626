import {
  type NormalisedProduct,
  type NormalisedProductVariant,
} from "@ts/components";
import { useEffect, useState } from "react";
import { useSizes } from "./useSizes";
import { useTemplate } from "./useTemplate";
import { type SelectedOption } from "@ts/shopify-storefront";
import { useCurrency } from "./useCurrency";

export type DropdownOption = {
  available: boolean;
  currentlyNotInStock: boolean;
  id?: string;
  isDisabled: boolean;
  label: string;
  lowStockThreshold: number;
  notify: boolean;
  quantityAvailable: number;
  value: string;
};

type SelectorOptions = {
  variants: NormalisedProductVariant[];
  accessories?: NormalisedProduct[];
  gender?: string;
  notify?: boolean;
  defaultVariant?: NormalisedProductVariant;
  loading?: boolean;
  optionKey?: string;
  tags?: string[];
};

export const useSelector = ({
  accessories,
  defaultVariant,
  gender,
  loading,
  notify,
  variants,
  tags,
  optionKey = "size",
}: SelectorOptions) => {
  const {
    product: { settingLowStockThreshold },
  } = useTemplate();
  const sizes = useSizes();
  const { formatCurrency } = useCurrency();
  const [selectedVariant, setSelectedVariant] =
    useState<NormalisedProductVariant>(null);
  const [selectedVariantAccessories, setSelectedVariantAccessories] =
    useState<NormalisedProductVariant[]>(null);
  const formattedGender = gender === "Mens" ? "men" : "women";
  const doesOptionHaveKey = (option: SelectedOption) => {
    return option?.name?.toLowerCase() === optionKey;
  };

  const selectedValue =
    selectedVariant?.selectedOptions?.find(doesOptionHaveKey)?.value;

  useEffect(() => {
    if (!loading && variants?.length === 1 && !selectedVariant) {
      const [variant] = variants;
      setSelectedVariant(variant);
      setSelectedVariantAccessories(findRelatedAccessoriesByVariant(variant));
    }
  }, [loading, variants, selectedVariant]);

  useEffect(() => {
    if (!selectedVariant && defaultVariant) {
      setSelectedVariant(defaultVariant);
      setSelectedVariantAccessories(
        findRelatedAccessoriesByVariant(defaultVariant),
      );
    }
  }, [defaultVariant]);
  const dropdownOptions = variants?.map((variant) => {
    const variantSizes =
      variant?.selectedOptions?.find(doesOptionHaveKey)?.value;
    const hasSameSize = sizes[formattedGender]?.find(
      (item) => item["bared"] === variantSizes,
    );
    const usSize = hasSameSize?.["us_au"];
    const option = {
      available: variant?.availableForSale,
      currentlyNotInStock: variant?.currentlyNotInStock,
      id: variant?.id,
      isDisabled: !variant?.availableForSale && !notify,
      isFinalSale: variant?.isFinalSale,
      isPreOrder: variant?.isPreOrder,
      label: `${variantSizes} ${usSize ? `(US ${usSize})` : ""}`,
      lowStockThreshold: Number(settingLowStockThreshold),
      notify,
      quantityAvailable: variant?.quantityAvailable,
      value: variantSizes,
    };

    if (tags?.includes("type:giftcard")) {
      // Gift cards cannot be sold with market pricing due to a shortcoming in Shopify
      // so we adjust the label to make it clear they're buying an AUD gift card and its cost
      // is based on current live exchange rate
      // See "Fixed pricing" section here: https://help.shopify.com/en/manual/markets/pricing/gift-cards
      if (variant?.priceV2?.currencyCode !== "AUD") {
        return {
          ...option,
          label: `${variant?.title} (~${variant?.priceV2?.currencyCode}${formatCurrency(variant?.priceV2?.amount, variant?.priceV2?.currencyCode)})`,
        };
      }

      return {
        ...option,
        label: variant?.title,
      };
    }

    return option;
  });

  const findRelatedAccessoriesByVariant = (
    variant?: NormalisedProductVariant,
  ) => {
    const size = variant?.selectedOptions?.find(doesOptionHaveKey)?.value;
    return accessories
      ?.map(({ variants }) => {
        if (variants?.length === 1) {
          return variants[0];
        }

        return variants?.find(
          (variant) =>
            variant.selectedOptions.find(doesOptionHaveKey)?.value === size,
        );
      })
      ?.filter((accessory) => !!accessory);
  };

  const findSelectedVariantAndAccessoriesBySize = (size: string) => {
    const variantForSize = variants?.find(
      (variant) =>
        variant.selectedOptions.find(doesOptionHaveKey)?.value === size,
    );
    const accessoriesForSize = accessories
      ?.map(({ variants }) => {
        if (variants?.length === 1) {
          return variants[0];
        }

        return variants?.find(
          (variant) =>
            variant.selectedOptions.find(doesOptionHaveKey)?.value === size,
        );
      })
      ?.filter((accessory) => !!accessory);

    return {
      variant: variantForSize,
      accessories: accessoriesForSize,
    };
  };

  const handleOptionChange = (size: string) => {
    const { variant, accessories } =
      findSelectedVariantAndAccessoriesBySize(size);
    setSelectedVariant(variant);
    setSelectedVariantAccessories(accessories);
  };

  return {
    dropdownOptions,
    findRelatedAccessoriesByVariant,
    findSelectedVariantAndAccessoriesBySize,
    doesOptionHaveKey,
    handleOptionChange,
    selectedValue,
    selectedVariant,
    selectedVariantAccessories,
    setSelectedVariant,
  };
};
