import Money from "@components/Money/Money";
import React from "react";
import tw, { styled } from "twin.macro";
import { CartTotalsOutputProps, withCartTotals } from "./withCartTotals";

const Row = tw.div`
  w-full flex justify-between mb-micro
`;

const Col = styled.span`
  ${({ right }) => (right ? tw`text-right` : tw`text-left`)}
  ${({ orange }) => (orange ? tw`text-warning` : tw`text-primary`)}
  ${({ bold, total }) =>
    total
      ? tw`leading-caption text-h3 font-medium`
      : bold
        ? tw`leading-double text-[18px] font-medium`
        : tw`leading-caption`}
`;

const Divider = tw.div`
  h-nano bg-primary w-full mt-mini mb-maxi
`;

const CartTotals = withCartTotals(
  ({
    amountDue,
    calculateAtCheckout,
    className,
    layout,
    subTotal,
    discountApplied,
    freeShipping,
    currencyCode,
    cartItemsQuantity,
    shippingLinePrice,
  }: CartTotalsOutputProps) => {
    return (
      <div className={className}>
        {layout === `cart` && (
          <Row>
            <Col>{`Subtotal (${cartItemsQuantity} ${cartItemsQuantity > 1 ? `items` : `item`})`}</Col>
            <Col right>
              <Money price={subTotal} />
            </Col>
          </Row>
        )}
        {discountApplied > 0 && (
          <Row>
            <Col>Discount</Col>
            <Col right>
              <Money
                price={{
                  amount: discountApplied,
                  currencyCode,
                }}
              />
            </Col>
          </Row>
        )}
        {layout === `cart` && (
          <Row>
            <Col>Estimated delivery costs</Col>
            <Col right orange={freeShipping}>
              {calculateAtCheckout && `Calculate at checkout`}
              {!calculateAtCheckout && (
                <Money
                  price={{
                    amount: shippingLinePrice || 0,
                    currencyCode,
                  }}
                  displayZeroAsFree={true}
                />
              )}
            </Col>
          </Row>
        )}
        {layout === `cart` && <Divider />}
        <Row>
          <Col bold>Total</Col>
          <Col right bold total={layout === `cart`}>
            <Money price={amountDue} />
          </Col>
        </Row>
      </div>
    );
  },
);

export default CartTotals;
