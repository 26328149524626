import React, { ElementType, Fragment, FC, forwardRef } from "react";
import { useApp } from "../../../hooks/useApp";
import { usePage } from "../../../hooks/usePage";
import { useRoutes } from "../../../hooks/useRoutes";
import { ComponentProps } from "../../../types/components";
import {
  SearchResultSanity,
  SearchResultSanityWithResolvedLink,
} from "../../../types/search";

export type SearchResultsPagesInputProps = ComponentProps & {
  data?: SearchResultSanity[];
  wrapper?: ElementType;
};

export type SearchResultsPagesOutputProps = Pick<
  SearchResultsPagesInputProps,
  "ref"
> & {
  data?: SearchResultSanityWithResolvedLink[];
  heading?: string;
  onClick: () => void;
};

export const withSearchResultsPages = (
  Component: FC<SearchResultsPagesOutputProps>,
) =>
  forwardRef<HTMLElement, SearchResultsPagesInputProps>(
    (
      {
        name = "SearchResultsPages",
        data,
        wrapper,
      }: SearchResultsPagesInputProps,
      ref,
    ) => {
      const { globalStateReducer } = useApp();
      const { routeResolver } = useRoutes();
      const {
        search: { pageResultsHeading: heading },
      } = usePage();
      const [, dispatch] = globalStateReducer;
      const Wrapper = wrapper || Fragment;

      const filteredData = data
        ?.filter((doc) => doc._type.startsWith("page"))
        .map((doc) => ({
          ...doc,
          link: {
            url: routeResolver({ item: doc }),
          },
        }));

      const handleOnClick = () => {
        dispatch({
          type: "setActiveSearch",
          payload: false,
        });
      };

      Component.displayName = name;

      if (filteredData.length == 0) {
        return null;
      }

      return (
        <Wrapper>
          <Component
            ref={ref}
            data={filteredData}
            heading={heading}
            onClick={handleOnClick}
          />
        </Wrapper>
      );
    },
  );
