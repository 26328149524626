/**
 * @typedef {import("./src/providers/global/config.js").Config} Config
 */

/** @type {import("type-fest").PartialDeep<Config>} */
export default {
  app: {
    url: "https://staging.baredfootweardev.com",
  },
  services: {
    functions: {
      endpoint: "https://api-bared-footwear-stage.web.app",
    },
  },
};
