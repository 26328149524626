import React from "react";
import { Animate } from "react-show";
import { ComponentProps } from "../../types/components";

export type AccordionAnimationProps = ComponentProps & {
  active: boolean;
};

export const AccordionAnimation = ({
  active,
  children,
  "data-testid": dataTestId,
}: AccordionAnimationProps) => (
  <Animate
    show={active}
    transitionOnMount
    preMount
    stayMounted
    style={{
      height: "auto",
      display: "block",
      overflow: "hidden",
    }}
    start={{
      height: 0,
      display: "block",
    }}
    data-testid={dataTestId}
  >
    {children}
  </Animate>
);
