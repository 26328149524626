import { VisuallyHidden } from "@components/Accessibility/VisuallyHidden/VisuallyHidden";
import { Icon } from "@components/Icon";
import { Link } from "@components/Link/Link";
import React from "react";
import tw from "twin.macro";
import { withHeaderBrand } from "./withHeaderBrand";

const BrandLink = tw(Link)`
  flex justify-center items-center mx-auto px-micro
`;
const Logo = tw(Icon)`
  text-primary
`;

const HeaderBrand = withHeaderBrand(({ title, url }) => (
  <BrandLink to={url} title={title}>
    <Logo name="baredLogo" size="logo" />
    <VisuallyHidden>{title}</VisuallyHidden>
  </BrandLink>
));

export default HeaderBrand;
