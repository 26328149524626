import { Link } from "@components/Link/Link";
import React from "react";
import tw from "twin.macro";
import { withFooterTerms } from "./withFooterTerms";

const List = tw.div`
  flex pb-0 px-midi md:justify-center md:px-0 md:pb-maxi 
`;
const ListItem = tw(Link)`
  text-caption underline leading-triple inline hover:text-secondary transition-presentation md:p-0
`;
const Divider = tw.span`
  px-micro 
`;

export const FooterTerms = withFooterTerms(({ items }) => (
  <List>
    {items?.map((item, index) => (
      <ListItem key={item.title} to={item.url}>
        {item.title}
        {index < items.length - 1 ? (
          <Divider data-testid="footer-terms-separator">|</Divider>
        ) : null}
      </ListItem>
    ))}
  </List>
));
