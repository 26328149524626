import React, { useEffect } from "react";

import {
  useTracker,
  TrackerHelper,
  KlaviyoAdapter,
  RudderStackAdapter,
} from "@dangreaves/react-tracker";

import { useFeatureFlags, useCustomer } from "~/hooks/useSession";

import "@dangreaves/react-tracker/style.css";

export function Tracker() {
  const tracker = useTracker();
  const customer = useCustomer();
  const featureFlags = useFeatureFlags();

  /**
   * Load tracker adapters.
   * Any events buffered prior to loading an adapter will be sent.
   */
  useEffect(() => {
    // Load RudderStack adapter when feature flag is enabled.
    if (true === featureFlags?.enable_rudderstack) {
      tracker.loadAdapter(
        new RudderStackAdapter({
          writeKey: "2pC2jJwYFOmWr02caQkfRa3Vkvw",
          dataPlaneUrl: "https://bareddanxhuwkw.dataplane.rudderstack.com",
        }),
      );
    }

    // Always load the Klaviyo adapter.
    tracker.loadAdapter(new KlaviyoAdapter({ companyId: "JnNaYr" }));
  }, [tracker, featureFlags]);

  /**
   * Identify the customer to the tracker if possible.
   *
   * @see https://www.rudderstack.com/docs/event-spec/standard-events/identify
   */
  useEffect(() => {
    if (!customer) return;

    tracker.identify(customer.id, {
      name: customer.displayName,
      ...(customer.firstName ? { firstName: customer.firstName } : {}),
      ...(customer.lastName ? { lastName: customer.lastName } : {}),
      ...(customer.email ? { email: customer.email } : {}),
      ...(customer.phone ? { phone: customer.phone } : {}),
    });
  }, [tracker, customer]);

  /**
   * The tracker helper can be displayed by appending ?enableTrackerHelper
   * to the URL.
   *
   * @see https://github.com/dangreaves/react-tracker
   */
  if (featureFlags?.enable_tracker_helper) {
    return <TrackerHelper />;
  }

  return null;
}
