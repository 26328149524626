import { useCart } from "@hooks/useCart";
import { useCore } from "@hooks/useCore";
import { useLocalisationContext } from "@hooks/useLocalisation";
import { useShopify } from "@hooks/useShopify";
import { ComponentProps } from "@ts/components";
import React, { FC } from "react";

export type DonationItemProps = {
  description: string;
  id?: string;
  image: string;
  price: number;
  selected: boolean;
  title: string;

  shopify?: {
    shopifyHandle?: string;
  };
};

export type CartDonationItemCardInputProps = ComponentProps & {
  item?: DonationItemProps;
  layout: string;
};

export type CartDonationItemCardOutputProps = CartDonationItemCardInputProps & {
  cartLoading: boolean;
  donationItem: DonationItemProps;
  handleSelect: () => void;
};

export const withCartDonationItemCard =
  (Component: FC<CartDonationItemCardOutputProps>) =>
  ({
    name = "CartDonationItemCard",
    item,
    layout,
  }: CartDonationItemCardInputProps) => {
    const {
      graphql: {
        queries: { GET_PRODUCT_LITE },
      },
    } = useCore();

    const { contextCountry } = useLocalisationContext();
    const { useQuery, productNormaliser } = useShopify();
    const { addToCart, loading: cartLoading } = useCart();

    const { data } = useQuery(GET_PRODUCT_LITE, {
      fetchPolicy: `cache-and-network`,
      variables: {
        countryCode: contextCountry,
        handle: item?.shopify?.shopifyHandle,
        firstImages: 0,
        firstVariants: 1,
      },
    });

    const { variants } = productNormaliser(data?.product);

    const mappedDonationItem = {
      id: variants?.length > 0 ? variants[0].id : null,
      title: item?.title,
      price: variants?.length > 0 ? variants[0].priceV2 : null,
      image: item?.image,
      description: data?.product?.description,
      selected: item?.selected,
    };

    const handleSelect = () => {
      if (!mappedDonationItem.id) {
        return false;
      }
      addToCart(mappedDonationItem.id);
      return true;
    };

    Component.displayName = name;
    return (
      <Component
        cartLoading={cartLoading}
        donationItem={mappedDonationItem}
        handleSelect={handleSelect}
        layout={layout}
      />
    );
  };
