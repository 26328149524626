import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { Icon } from "@components/Icon";
import { Link } from "@components/Link/Link";
import {
  BackgroundContainer,
  StyledContainer,
} from "@components/Styled/Container";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import { StyledH5 } from "@components/Styled/Text";
import { type NormalisedNavigation } from "@hooks/useNavigation";
import { Role } from "ariakit";
import {
  MenuButton as AccessibleMenuButton,
  MenuButtonArrow as AccessibleMenuButtonArrow,
  MenuItem as AccessibleMenuItem,
} from "ariakit/menu";
import React from "react";
import tw, { styled } from "twin.macro";
import NavigationHeaderSubImage from "./NavigationHeaderSubImage";
import NavigationHeaderSubShoeIcon from "./NavigationHeaderSubShoeIcon";
import { withNavigationHeaderSub } from "./withNavigationHeaderSub";

type MenuListItemsProps = {
  menuItems?: NormalisedNavigation[];
  title?: string;
};

const Container = tw(StyledContainer)`text-secondary shadow-header`;

const BgContainer = styledWithInvalidProps(BackgroundContainer)(
  ({ active }) => [
    tw`fixed left-0 z-50 flex-col hidden h-full border-t top-[122px] border-tertiary lg:flex`,
    active ? tw`flex!` : tw`hidden!`,
  ],
);

const Wrapper = styled(Role)`
  ${tw`flex flex-row w-full max-w-2xl mx-auto bg-white pt-mega pb-giga md:px-giga xl:px-tera`}
  ${({ contents }) => (contents ? tw`justify-between` : tw`justify-start`)}
`;

const Mask = styledWithInvalidProps("div")(({ active }) => [
  tw`w-full h-full`,
  active
    ? tw`transition-opacity bg-primary opacity-90 duration-slow`
    : tw`opacity-0`,
]);

const MenuListWrapper = tw.div`
  flex flex-col w-2/10 mr-maxi xl:w-1/7
`;

const MenuList = tw.ul`
  flex flex-col
`;

const MenuListTitle = tw(StyledH5)`
  uppercase text-secondary border-b border-tertiary pb-mini mb-1
`;

const IconListWrapper = tw.div`
  w-4/10
`;

const IconList = tw.ul`
  flex flex-wrap w-full
`;

const IconListItem = tw.li`
  w-full grid grid-cols-4 lg:grid-cols-3 xl:grid-cols-4
`;

const MenuItemList = tw.li`
  py-1
`;

const MenuItem = styledWithInvalidProps(AccessibleMenuItem)(({ highlight }) => [
  tw`leading-body hover:text-primary transition-presentation md:text-hint xl:text-body`,
  highlight ? tw`font-medium border-b-2 text-secondary pb-pico` : null,
]);

const ShoeIcon = tw(NavigationHeaderSubShoeIcon)`
  px-pico
`;

const ImageListWrapper = styled.div`
  ${({ contents }) => (!contents ? tw`ml-exa` : null)}
  ${tw`w-4/10`}
`;

const ImageList = tw.div`
  flex flex-wrap w-full justify-end
`;

const ImageItem = styled.div`
  ${({ size }) => (size > 1 ? tw`md:w-5/12 xl:w-4/9` : tw`md:w-9/10 xl:w-4/5`)}
  ${tw`m-micro mb-mini`}
`;

const MenuButton = withFocusRing(tw(AccessibleMenuButton)`ml-pico self-center`);

const MenuButtonArrow = withFocusRing(
  styledWithInvalidProps(
    AccessibleMenuButtonArrow,
    (prop) => prop === "state",
  )(({ active }) => [
    tw`flex items-center justify-center transform rotate-90 transform-gpu`,
    active ? tw`-rotate-90` : null,
  ]),
);

const Image = tw(NavigationHeaderSubImage)``;

const MenuListItems = ({ title, menuItems }: MenuListItemsProps) => (
  <MenuListWrapper>
    {title ? <MenuListTitle as="span">{title}</MenuListTitle> : null}
    <MenuList>
      {menuItems?.map((item, index) => (
        <MenuItemList key={`${item?.title}-${index}`}>
          <MenuItem
            role="menuitem"
            as={item?.url ? Link : `div`}
            to={item?.url}
            highlight={item?.highlightLink}
          >
            {item?.title}
          </MenuItem>
        </MenuItemList>
      ))}
    </MenuList>
  </MenuListWrapper>
);

const NavigationHeaderSub = withNavigationHeaderSub(
  ({
    active,
    collectionTitle,
    contents,
    dataTestId,
    desktopImages,
    featuredTitle,
    handleHover,
    items,
    menuProps,
    menuState,
    shopEditItems,
    shopEditTitle,
    title,
  }) => (
    <>
      <MenuButton state={menuState} onClick={handleHover(title)}>
        <MenuButtonArrow active={active} state={menuState}>
          <Icon name="chevron" size="xxs" />
        </MenuButtonArrow>
      </MenuButton>
      <BgContainer
        as="nav"
        width="full"
        active={active}
        data-testid={dataTestId}
        onClick={handleHover(null)}
      >
        <Container background="lightest" width="full">
          <Wrapper
            {...menuProps}
            tabIndex="-1"
            forwardAs={Role}
            state={menuState}
            contents={!!contents?.length ? "true" : undefined}
            active={active}
          >
            <MenuListItems title={featuredTitle} menuItems={items} />
            {!!shopEditItems?.length ? (
              <MenuListItems title={shopEditTitle} menuItems={shopEditItems} />
            ) : null}
            {!!contents?.length ? (
              <IconListWrapper>
                {collectionTitle && (
                  <MenuListTitle>{collectionTitle}</MenuListTitle>
                )}
                <IconList>
                  <IconListItem>
                    {contents?.map((content, index) => (
                      <ShoeIcon
                        key={`${content.title}-${index}`}
                        title={content.title}
                        icon={content.icon}
                        url={content.url}
                      />
                    ))}
                  </IconListItem>
                </IconList>
              </IconListWrapper>
            ) : null}
            <ImageListWrapper
              contents={!!contents?.length ? "true" : undefined}
            >
              <ImageList>
                {desktopImages?.map((imageItem, index) => (
                  <ImageItem
                    key={`${imageItem.title}-${index}`}
                    size={desktopImages.length}
                    data-testid={`header-navigation-sub-images-${imageItem._key}`}
                  >
                    <Image
                      image={imageItem}
                      data-testid={`header-navigation-images-${imageItem._key}}`}
                    />
                  </ImageItem>
                ))}
              </ImageList>
            </ImageListWrapper>
          </Wrapper>
        </Container>
        <Mask active={active} onMouseEnter={handleHover(null)} />
      </BgContainer>
    </>
  ),
);

export default NavigationHeaderSub;
