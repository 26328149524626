import React from "react";
import clsx from "clsx";

import { withCartLineItemPrice } from "./withCartLineItemPrice";

export const CartLineItemPrice = withCartLineItemPrice(
  ({
    onSale,
    currencyCode,
    price,
    compareAtPrice,
    priceAfterDiscount,
    className,
    layout,
  }) => (
    <div className={className}>
      {onSale ? (
        <div
          className={clsx(
            layout === "cart" && "flex flex-row",
            "flex flex-col justify-end",
          )}
        >
          {!compareAtPrice ? (
            <s>
              {currencyCode}
              {price}
            </s>
          ) : (
            <s>
              {currencyCode}
              {compareAtPrice}
            </s>
          )}

          <span
            className={clsx(
              onSale && "font-medium text-primary",
              layout === "product"
                ? "mt-pico"
                : layout === "cart"
                  ? "ml-pico"
                  : "mt-nano",
            )}
          >
            {currencyCode}${priceAfterDiscount}
          </span>
        </div>
      ) : (
        <span>
          {currencyCode}
          {price}
        </span>
      )}
    </div>
  ),
);
