import ls from "localstorage-slim";
import { useLocation } from "@reach/router";
import React, { useMemo, useCallback } from "react";
import {
  GeofencingModal as _GeofencingModal,
  SanityRichText,
  useModal,
} from "@bared/ui";

import { useSite } from "~/hooks/useSite";
import { useSiteHref } from "~/hooks/useSiteHref";
import { useLocalSite } from "~/hooks/useLocalSite";

import { useRouteLoaderData } from "~/hooks/useRouteLoaderData";

export interface GeofencingModalProps {
  //
}

export function GeofencingModal() {
  const site = useSite();
  const localSite = useLocalSite();

  const { hideModal } = useModal();

  const { geofencingLocal, geofencingCurrent } =
    useRouteLoaderData("routes/$site");

  const location = useLocation();

  // Get current path but for the local site.
  const localSiteHref = useSiteHref({
    site: localSite,
    href: location.pathname + location.search,
  });

  const localStorageKey = `geofencing_modal_dismissed_${site.handle}`;

  /**
   * Determine if the modal should be shown to the user.
   */
  const shouldShowModal = useMemo(() => {
    // User is already on local site.
    if (site.handle === localSite.handle) return false;

    // User has already dismissed the modal in the last 24 hours.
    const isDismissed = ls.get<boolean>(localStorageKey);
    if (isDismissed) return false;

    // Should show modal.
    return true;
  }, [site, localSite, localStorageKey]);

  /**
   * When modal dismissed, do not show again for 24 hours.
   */
  const onDismiss = useCallback(() => {
    ls.set<boolean>(localStorageKey, true, { ttl: 86400 });
  }, [localStorageKey]);

  /**
   * When "local" site selected, leave modal open and hard navigate to local site.
   */
  const localSiteOnClick = useCallback(() => {
    window.location.href = localSiteHref;
  }, [localSiteHref]);

  /**
   * When "current" site selected, close the modal, do not show again for 24 hours.
   */
  const currentSiteOnClick = useCallback(() => {
    hideModal(MODAL_ID);
    onDismiss();
  }, [hideModal, onDismiss]);

  if (!geofencingLocal || !geofencingCurrent || !shouldShowModal) return null;

  return (
    <_GeofencingModal
      id={MODAL_ID}
      defaultVisible={true}
      onDismiss={onDismiss}
      localSiteButton={{
        label: geofencingLocal.redirectCTA,
        onClick: localSiteOnClick,
      }}
      currentSiteButton={{
        label: geofencingCurrent.continueCTA,
        onClick: currentSiteOnClick,
      }}
    >
      {geofencingLocal.content && (
        <SanityRichText value={geofencingLocal.content} />
      )}
    </_GeofencingModal>
  );
}

const MODAL_ID = "geofencing-modal";
