import { useCheckout, useCheckoutContext } from "@hooks/useCheckout";
import { ComponentProps } from "@ts/components";
import { CurrencyCode, MoneyV2 } from "@ts/shopify-storefront";
import React, { FC } from "react";

export type CartTotalsInputProps = ComponentProps & {
  layout: string;
};

export type CartTotalsOutputProps = CartTotalsInputProps & {
  amountDue: MoneyV2;
  subTotal: MoneyV2;
  freeShipping: boolean;
  currencyCode: CurrencyCode;
  discountApplied: number;
  cartItemsQuantity: number;
  shippingLinePrice: number;
  calculateAtCheckout: boolean;
};

export const withCartTotals =
  (Component: FC<CartTotalsOutputProps>) =>
  ({
    name = "CartTotals",
    className,
    layout = "cart",
  }: CartTotalsInputProps) => {
    const { checkout } = useCheckoutContext();
    const { calculateLineItemsQuantityTotal } = useCheckout();

    const amountDue = checkout?.paymentDueV2;
    const subTotal = checkout?.lineItemsSubtotalPrice;
    const shippingLinePrice = parseFloat(checkout?.shippingLine?.price?.amount);
    const currencyCode = checkout?.totalPriceV2?.currencyCode;
    const hasPassedFreeShippingThreshold = false;
    const freeShipping = false;
    const calculateAtCheckout =
      !checkout?.shippingLine?.price?.amount && !hasPassedFreeShippingThreshold;
    const discountApplied =
      parseFloat(checkout?.lineItemsSubtotalPrice?.amount) -
      parseFloat(checkout?.totalPriceV2?.amount);
    const cartItemsQuantity = calculateLineItemsQuantityTotal();

    Component.displayName = name;
    return (
      <Component
        amountDue={amountDue}
        calculateAtCheckout={calculateAtCheckout}
        cartItemsQuantity={cartItemsQuantity}
        className={className}
        currencyCode={currencyCode}
        discountApplied={discountApplied}
        freeShipping={freeShipping}
        layout={layout}
        shippingLinePrice={shippingLinePrice}
        subTotal={subTotal}
      />
    );
  };
