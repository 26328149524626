import { Accordion } from "@components/Accordion/Accordion";
import { Link } from "@components/Link/Link";
import React from "react";
import tw from "twin.macro";
import { withNavigationFooter } from "./withNavigationFooter";
import { IconExternal } from "@bared/ui";

const Wrapper = tw.div`
  w-full
`;
const Desktop = tw.div`
  hidden w-full justify-between pb-mini md:flex 
`;
const Mobile = tw.div`
  block md:hidden
`;
const DesktopList = tw.div`
  block pb-mini
`;
const MobileList = tw.div`
  pb-maxi
`;
const Title = tw.p`
  block text-hint leading-body mb-mini mr-1 uppercase font-medium tracking-heading
`;
const ListItem = tw(Link)`
  block text-body leading-[1.5] transition-presentation text-primary 
`;

const NavigationFooter = withNavigationFooter(({ items }) => (
  <Wrapper>
    <Desktop>
      {items?.map((item) => (
        <DesktopList key={item?.title}>
          <Title>{item?.title}</Title>
          {item?.items?.map((listItem) => (
            <ListItem key={listItem.title} to={listItem.url}>
              <div className="mb-mini mr-mini flex items-baseline text-primary hover:text-secondary">
                {listItem.externalIcon ? (
                  <IconExternal className="mr-micro h-[12px] w-[12px]" />
                ) : null}
                {listItem.title}
              </div>
            </ListItem>
          ))}
        </DesktopList>
      ))}
    </Desktop>
    <Mobile>
      {items?.map((item) => (
        <Accordion
          key={item?.title}
          title={item?.title}
          size="footer"
          icon={item?.items?.length > 0}
        >
          <MobileList>
            {item?.items?.map((listItem) => (
              <ListItem key={listItem.title} to={listItem.url}>
                <div className="mb-mini mr-mini flex items-baseline text-primary hover:text-secondary">
                  {listItem.externalIcon ? (
                    <IconExternal className="mr-micro h-[12px] w-[12px]" />
                  ) : null}
                  {listItem.title}
                </div>
              </ListItem>
            ))}
          </MobileList>
        </Accordion>
      ))}
    </Mobile>
  </Wrapper>
));

export default NavigationFooter;
