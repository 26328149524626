import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import React from "react";
import tw, { styled } from "twin.macro";
import { PriceOutputProps, withPrice } from "./withPrice";

const Wrapper = styled.div`
  ${({ layout }) => {
    if (layout === `cart`) return tw`flex flex-row`;
    if (layout === `card`) return tw`flex flex-col justify-center`;
    else return tw`flex flex-row items-end justify-start text-[18px]`;
  }}
`;

const Money = styledWithInvalidProps("div")(({ sale, layout }) => [
  sale && tw`font-medium text-primary ml-micro`,
  layout === `product` ? tw`text-[18px]` : null,
  layout === `cart` ? tw`text-hint` : null,
  layout === `card` ? tw`ml-0 md:mt-nano` : null,
]);

const Price = withPrice(
  ({
    onSale,
    currencyCode,
    price,
    comparedPrice,
    layout,
  }: PriceOutputProps) => (
    <div>
      {onSale ? (
        <Wrapper layout={layout}>
          <s data-testid="product-price-normal">
            {currencyCode}
            {comparedPrice}
          </s>
          <Money
            sale={onSale}
            layout={layout}
            data-testid="product-price-discounted"
          >
            {currencyCode}
            {price}
          </Money>
        </Wrapper>
      ) : (
        <Wrapper layout={layout}>
          <Money layout={layout} data-testid="product-price-normal">
            {currencyCode}
            {price}
          </Money>
        </Wrapper>
      )}
    </div>
  ),
);

export default Price;
