import { useRouteLoaderData } from "~/hooks/useRouteLoaderData";

import { useSiteHref } from "./useSiteHref";

/**
 * Given an href, return it localized to the current site.
 */
export function useHref(href: string) {
  const { site } = useRouteLoaderData("routes/$site");
  return useSiteHref({ href, site });
}
