import {
  type NestedDashboardSlug,
  type NormalisedProductVariant,
} from "@ts/components";
import React, {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useReducer,
  useState,
} from "react";

import type { Config, ConfigStore } from "@ts/config";

export type AppContextInputProps = PropsWithChildren<{
  config: Config;
}>;

export type AppContextOutputProps = {
  activeNestedTab: NestedDashboardSlug;
  addToCartError: boolean;
  config: Config;
  defaultCurrency: string;
  globalStateReducer: [state: GlobalState, dispatch: Dispatch<GlobalAction>];
  newAddressId: string;
  pagination: {
    handle: string;
    page: number;
  };
  searchResultNumber: number;
  searchTerms: string;
  selectedStore: ConfigStore;
  setActiveNestedTab: Dispatch<SetStateAction<NestedDashboardSlug>>;
  setAddToCartError: Dispatch<SetStateAction<boolean>>;
  setNewAddressId: Dispatch<SetStateAction<string>>;
  setPagination: Dispatch<SetStateAction<{ handle: string; page: number }>>;
  setSearchResultNumber: Dispatch<SetStateAction<number>>;
  setSearchTerms: Dispatch<SetStateAction<string>>;
  setSelectedStore: Dispatch<SetStateAction<ConfigStore>>;
  siteLocation: string;
};

export type GlobalState = {
  activeAddToCartError: boolean;
  activeCart: boolean | { activeCart: boolean; newItemAdded: false };
  activeMenu: boolean;
  newItemAdded: boolean;
  activeSearch: boolean;
  selectedVariant?: NormalisedProductVariant;
};

export type GlobalAction = {
  payload?: boolean | { activeCart: boolean; newItemAdded: boolean };
  selectedVariant?: NormalisedProductVariant;
  type: string;
};

export const AppContext = React.createContext<
  AppContextOutputProps | undefined
>(undefined);

export const AppProvider = ({ children, config }: AppContextInputProps) => {
  const [searchResultNumber, setSearchResultNumber] = useState(0);
  const [searchTerms, setSearchTerms] = useState("");
  const [selectedStore, setSelectedStore] = useState({});
  const [newAddressId, setNewAddressId] = useState("");
  const [pagination, setPagination] = useState({ handle: null, page: 1 });
  const [addToCartError, setAddToCartError] = useState(false);
  const [activeNestedTab, setActiveNestedTab] =
    useState<NestedDashboardSlug>("gift-card");

  const { stores } = config;

  const [storeName] = Object.keys(stores);
  const { siteLocation, defaultCurrency } = stores[storeName] || {};

  const initialState: GlobalState = {
    activeCart: false,
    activeAddToCartError: false,
    activeSearch: false,
    activeMenu: false,
    selectedVariant: null,
  };

  const reducer = (state: GlobalState, action: GlobalAction): GlobalState => {
    switch (action.type) {
      case "setActiveCart":
        return {
          ...state,
          activeCart: action.payload,
          activeSearch: false,
          activeMenu: false,
        };
      case "setActiveSearch":
        return {
          ...state,
          activeSearch: action.payload as boolean,
          activeCart: false,
          activeMenu: false,
        };
      case "setActiveMenu":
        return {
          ...state,
          activeMenu: action.payload as boolean,
          activeSearch: false,
          activeCart: false,
        };
      case "navigationClicked":
        return {
          ...state,
          activeMenu: false,
          activeSearch: false,
          activeCart: false,
        };
      case "setSelectedVariant":
        return { ...state, selectedVariant: action.selectedVariant };
      default:
        return state;
    }
  };

  const globalStateReducer = useReducer(reducer, initialState);

  const values = useMemo(
    () => ({
      activeNestedTab,
      addToCartError,
      config,
      defaultCurrency,
      globalStateReducer,
      newAddressId,
      pagination,
      searchResultNumber,
      searchTerms,
      selectedStore,
      setActiveNestedTab,
      setAddToCartError,
      setNewAddressId,
      setPagination,
      setSearchResultNumber,
      setSearchTerms,
      setSelectedStore,
      siteLocation,
    }),
    [
      activeNestedTab,
      addToCartError,
      config,
      defaultCurrency,
      globalStateReducer,
      newAddressId,
      pagination,
      searchResultNumber,
      searchTerms,
      selectedStore,
      setActiveNestedTab,
      setAddToCartError,
      setNewAddressId,
      setPagination,
      setSearchResultNumber,
      setSearchTerms,
      setSelectedStore,
      siteLocation,
    ],
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
