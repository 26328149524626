import React from "react";
import { AnnouncementOutputProps, withAnnouncement } from "./withAnnouncement";
import { SanityLink } from "@bared/ui";
import clsx from "clsx";

const Announcement = withAnnouncement(
  ({ items, active }: AnnouncementOutputProps) => {
    return (
      <div className="left-0 top-0 absolute h-full w-full text-center">
        {items.map(({ title, textLink }, index) => {
          const link = textLink && textLink.length > 0 ? textLink[0] : null;

          return (
            <>
              {link ? (
                <SanityLink
                  className={clsx(
                    active === index
                      ? "translate-y-0 visible opacity-100"
                      : "-translate-y-6 invisible opacity-0",
                    "inset-0 absolute flex items-center justify-center text-center transition-[layout,transform] hover:!text-white hover:opacity-80",
                  )}
                  data={link}
                  target={link._type === "link" ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  key={`${title}-${index}`}
                >
                  {title}
                </SanityLink>
              ) : (
                <div
                  key={`${title}-${index}`}
                  className={clsx(
                    active === index
                      ? "translate-y-0 visible opacity-100"
                      : "-translate-y-6 invisible opacity-0",
                    "inset-0 absolute flex items-center justify-center text-center transition-[layout,transform] hover:!text-white hover:opacity-80",
                  )}
                >
                  {title}
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  },
);

export default Announcement;
