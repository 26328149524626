import isPropValid from "@emotion/is-prop-valid";
import { FC } from "react";
import { styled } from "twin.macro";

export const styledWithInvalidProps = <T>(
  tag: string | FC<T>,
  comparator?: (prop: string) => boolean,
): ((props) => (props) => JSX.Element) =>
  styled<T>(tag, {
    shouldForwardProp: (prop) =>
      comparator ? comparator(prop) || isPropValid(prop) : isPropValid(prop),
  });
