import { Link } from "@components/Link/Link";
import { isEmpty } from "lodash";
import React from "react";
import tw, { styled } from "twin.macro";
import { Image } from "../Image/Image";
import { StyledH5, StyledP } from "../Styled/Text";
import {
  NavigationHeaderSubImageOutputProps,
  withNavigationHeaderSubImage,
} from "./withNavigationHeaderSubImage";

type ImageTextProps = {
  title?: string;
  heading?: string;
};

const Desktop = styled.div`
  ${tw`hidden w-full lg:block`}
`;

const Mobile = styled.div`
  ${tw`block w-full lg:hidden`}
`;

const Title = tw(StyledH5)`text-primary uppercase`;
const Heading = tw(StyledP)`text-secondary`;

const ImageTextWrapper = tw.div`
  mt-micro
`;

const ImageText = ({ title, heading }: ImageTextProps) => {
  return (
    <ImageTextWrapper>
      {title && <Title>{title}</Title>}
      {heading && <Heading>{heading}</Heading>}
    </ImageTextWrapper>
  );
};

const NavigationHeaderSubImage = withNavigationHeaderSubImage(
  ({
    title,
    heading,
    desktopImage,
    desktopAspectRatio,
    desktopImageLink,
    mobileImage,
    mobileAspectRatio,
    mobileImageLink,
    dataTestId,
  }: NavigationHeaderSubImageOutputProps) => (
    <>
      {!isEmpty(desktopImage) && (
        <Desktop
          as={desktopImageLink?.url ? Link : null}
          to={desktopImageLink?.url}
          data-testid={`desktop-${dataTestId}`}
        >
          <Image
            image={desktopImage}
            alt={desktopImage?.alt}
            ratio={desktopAspectRatio}
          />
          <ImageText title={title} heading={heading} />
        </Desktop>
      )}

      {!isEmpty(mobileImage) && (
        <Mobile
          as={mobileImageLink?.url ? Link : null}
          to={mobileImageLink?.url}
          data-testid={`mobile-${dataTestId}`}
        >
          <Image
            image={mobileImage}
            alt={mobileImage?.alt}
            ratio={mobileAspectRatio}
          />
          <ImageText title={title} heading={heading} />
        </Mobile>
      )}
    </>
  ),
);

export default NavigationHeaderSubImage;
