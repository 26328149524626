import { Link } from "@components/Link/Link";
import { StyledButton } from "@components/Styled/Button";
import { StyledContainer } from "@components/Styled/Container";
import { StyledH4, StyledH5 } from "@components/Styled/Text";
import { css } from "@emotion/react";
import React from "react";
import tw, { styled } from "twin.macro";
import {
  CountdownTimerOutputProps,
  withCountdownTimer,
} from "./withCountdownTimer";

const Container = styled(StyledContainer)`
  ${({ backgroundColour }) => {
    if (backgroundColour)
      return css`
        background-color: ${backgroundColour};
      `;
    return tw`bg-primary`;
  }}
  ${({ textColour }) => {
    if (textColour)
      return css`
        color: ${textColour};
      `;
    return tw`text-white`;
  }}
  ${({ hasLink }) => (hasLink ? tw`h-tera` : tw`h-mega`)}
  ${({ layout }) => layout === "product" && tw`h-mega mb-mini`}
  ${tw`flex items-center justify-evenly md:h-mega md:justify-center`}
`;

const Wrapper = styled.div`
  ${({ layout, hasLink }) =>
    layout === "product"
      ? tw`justify-around w-full lg:flex-row md:flex-col md:mr-0 md:w-full`
      : hasLink
        ? tw`flex-col max-w-[250px] md:flex-row md:max-w-full md:mr-mini`
        : tw`justify-center w-full md:w-auto`}

  ${tw`flex items-center text-center `}
`;

const Heading = tw(StyledH4)`
  text-body font-display leading-body md:text-[1.12rem] lg:text-[1.185rem]
`;

const TimerWrapper = tw.div`
  flex justify-center mt-nano md:mt-pico
`;

const Label = tw(StyledH5)`
  text-caption mx-micro leading-body pb-0 lg:text-hint
`;

const Time = tw(StyledH5)`
  text-caption min-w-mega font-bold leading-body pb-0 lg:text-hint
`;

const Initials = styled.abbr`
  ${tw`font-normal`}
  & {
    text-decoration: none !important;
  }
`;

const Button = styled(StyledButton)`
  ${tw`text-caption text-center mr-mini font-body leading-body tracking-heading uppercase flex-col md:mr-0 md:mt-pico lg:text-hint lg:mt-nano`}
  ${({ hasLink }) => !hasLink && tw`hidden`}
`;

const Dash = styled.div`
  ${tw`w-full -mt-nano h-[1px]`}
  ${({ textColour }) =>
    textColour
      ? css`
          background-color: ${textColour};
        `
      : tw`bg-white`}
`;

export const CountdownTimer = ({
  layout,
  timers,
  hour,
  minutes,
  seconds,
  handleClick,
}: CountdownTimerOutputProps) => {
  const { backgroundColour, textColour, pdpHeading, heading, label, link } =
    timers;

  return (
    <Container
      data-testid="countdown-timer-container"
      width={"full"}
      backgroundColour={backgroundColour?.hex}
      textColour={textColour?.hex}
      layout={layout}
      hasLink={link.url}
    >
      <Wrapper layout={layout} hasLink={link.url}>
        <Heading>{layout === "product" ? pdpHeading : heading}</Heading>
        <TimerWrapper>
          <Label>{label}: </Label>
          <Time>
            {hour} <Initials title="Hours">H</Initials>&nbsp;
          </Time>
          <Time>
            {minutes} <Initials title="Minutes">M</Initials>&nbsp;
          </Time>
          <Time>
            {seconds} <Initials title="Seconds">S</Initials>
          </Time>
        </TimerWrapper>
      </Wrapper>
      {layout !== "product" && (
        <Button
          as={Link}
          to={link?.url}
          handleCLick={handleClick}
          hasLink={link.url}
        >
          {link?.title}
          <Dash textColour={textColour?.hex} />
        </Button>
      )}
    </Container>
  );
};

export default withCountdownTimer(CountdownTimer);
