import {
  type ComponentProps,
  type GatsbyCustomImage,
  type GatsbyImageSourceProps,
  type ResolvedLink,
} from "@ts/components";
import { type ThemeAspectRatio } from "@ts/theme";
import React, { type FC } from "react";

export type ImageProps = ComponentProps & {
  alt?: string;
  cover?: boolean;
  critical?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  hoverImage?: string;
  image?: GatsbyImageSourceProps | GatsbyCustomImage | string;
  imageLink?: ResolvedLink;
  maxWidth?: number;
  onClick?: (link?: ResolvedLink) => void;
  ratio?: "none" | Extract<keyof ThemeAspectRatio, string>;
  staticImage?: boolean;
  loading?: "lazy" | "eager";
  hoverSrc?: string;
  imageUrl?: (src: string, size?: string | number) => string;
  imageSrcSets?: (src: string, size?: string | number) => string;
  useSrcSet?: boolean;
};

export const withImage =
  (Component: FC<ImageProps>) =>
  ({ ...props }: ImageProps) => {
    return <Component {...props} />;
  };
