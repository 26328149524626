import React from "react";
import { useTemplate } from "../../../hooks/useTemplate";

export const withAddToCartErrorPopup =
  (Component) =>
  ({ name = "AddToCartErrorPopup", active, setActive }) => {
    const { product } = useTemplate();
    Component.displayName = name;
    return (
      <Component
        active={active}
        setActive={setActive}
        additionalErrorTitle={product?.additionalErrorTitle}
        additionalErrorDescription={product?.additionalErrorDescription}
        additionalErrorButton={product?.additionalErrorButton}
      />
    );
  };
