import { type SearchResultSanity } from "@ts/search";
import React, {
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
  useMemo,
  useState,
} from "react";

export type SearchContextValues = {
  sanitySearchResults?: SearchResultSanity[];
  setSanitySearchResults?: Dispatch<
    SetStateAction<SearchResultSanity[] | undefined>
  >;
};

export const SearchContext = React.createContext<SearchContextValues>({});

export const SearchProvider = ({ children }: PropsWithChildren) => {
  const [sanitySearchResults, setSanitySearchResults] =
    useState<SearchResultSanity[]>();

  const values = useMemo(
    () => ({
      sanitySearchResults,
      setSanitySearchResults,
    }),
    [sanitySearchResults, setSanitySearchResults],
  );

  return (
    <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
  );
};
