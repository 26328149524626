import { Currency } from "@components/Currency/Currency";
import { Link } from "@components/Link/Link";
import React from "react";
import tw from "twin.macro";
import { withNavigationTopBar } from "./withNavigationTopBar";

const Container = tw.div`
  flex flex-row z-30
`;
const Wrapper = tw.div`
  hidden md:block
`;
const StyledLink = tw(Link)`
  mr-maxi hover:!text-white hover:opacity-80
`;

const HeaderTopBarMenu = withNavigationTopBar(({ items }) => (
  <Container>
    <Currency />
    <Wrapper>
      {items?.map((item) => (
        <StyledLink key={item.title} to={item.url}>
          {item.title}
        </StyledLink>
      ))}
    </Wrapper>
  </Container>
));

export default HeaderTopBarMenu;
