import { WithGatsbyImage, WithReference } from "@ts/components";
import {
  Collection,
  DeliveryOption,
  SanityKeyed,
  ServiceHighlight,
  SettingAchievements,
  SettingAcknowledgementOfCountry,
  SettingBuyNowPayLater,
  SettingFindInStore,
  SettingFlashSale,
  SettingForms,
  SettingOrganisation,
  SettingPreferences,
  SettingSale,
  SettingServiceHighlights,
  SettingSocial,
  SettingTabbedContent,
  SettingTracking,
  Store,
  Timer,
} from "@ts/sanity";
import { graphql, useStaticQuery } from "gatsby";
import { useLocalisation } from "./useLocalisation";

export type UseSettings = {
  achievements?: SettingAchievements;
  social?: SettingSocial;
  copyright?: SettingPreferences;
  buyNowPayLater?: SettingBuyNowPayLater;
  findInStore?: SettingFindInStore;
  countdownTimer?: {
    timers?: WithReference<Timer, "collections", Collection[]>[];
  };
  forms?: {
    edges?: {
      node: SettingForms;
    }[];
  };
  tracking?: SettingTracking;
  organisation?: SettingOrganisation;
  allStores?: {
    edges: {
      node: Store;
    }[];
  };
  sale?: SettingSale;
  flashSale?: SettingFlashSale;
  acknowledgementOfCountry?: SettingAcknowledgementOfCountry;
  serviceHighlights?: Partial<Omit<SettingServiceHighlights, "items">> & {
    items: WithGatsbyImage<SanityKeyed<ServiceHighlight>, "image">[];
  };
  tabbedContent?: SettingTabbedContent;
};

export const useSettings = (): UseSettings => {
  const settings = useStaticQuery(graphql`
    query SANITY_SETTINGS {
      social: sanitySettingSocial {
        channelsTitle
        channels {
          title
          url
          userName
        }
        channelsForMenTitle
        channelsForMen {
          title
          url
          userName
        }
      }
      copyright: sanitySettingPreferences {
        copyright
      }
      allBuyNowPayLater: allSanitySettingBuyNowPayLater {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            enabled
            label
            providers {
              title
              countries {
                value
              }
              logo {
                alt
                displayHeight
                displayWidth
                asset {
                  url
                }
              }
              costBreakdownEnabled
            }
          }
        }
      }
      allFindInStore: allSanitySettingFindInStore {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            enabled
          }
        }
      }
      countdownTimer: sanitySettingCountdownTimer {
        ...CountdownTimer
      }
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
              options
            }
          }
        }
      }
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        googleAnalyticsId
        facebookPixelId
        facebookAppId
      }
      allStores: allSanityStore(
        filter: { enableCollect: { eq: true } }
        sort: { displayOrder: ASC }
      ) {
        edges {
          node {
            ...GatsbyStoreFragment
          }
        }
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      sale: sanitySettingSale {
        enabled
        type
      }
      flashSale: sanitySettingFlashSale {
        enabled
        title
        description
        slug {
          current
        }
        collections {
          id
        }
      }
      acknowledgementOfCountry: sanitySettingAcknowledgementOfCountry {
        acknowledgementOfCountry: _rawAcknowledgementOfCountry(
          resolveReferences: { maxDepth: 2 }
        )
      }
      allServiceHighlights: allSanitySettingServiceHighlights {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            enabled
            delay
            items {
              ...ServiceHighlight
            }
          }
        }
      }
      achievements: sanitySettingAchievements {
        enabled
        icons {
          alt
          displayWidth
          imagelink {
            ...InternalLink
            ...Link
          }
          asset {
            url
          }
        }
      }
      tabbedContent: sanitySettingTabbedContent {
        tabs {
          _key
          _type
          title
          excludeUrls
          useShopifyDescription
          displayAbove
          content: _rawContent(resolveReferences: { maxDepth: 2 })
          features {
            ...ProductFeature
          }
        }
      }
    }
  `);

  const { findNodeByLocale } = useLocalisation();
  const { allFindInStore, allServiceHighlights, allBuyNowPayLater, ...rest } =
    settings;

  return {
    ...rest,
    buyNowPayLater: findNodeByLocale<SettingBuyNowPayLater>(allBuyNowPayLater),
    findInStore: findNodeByLocale<SettingFindInStore>(allFindInStore),
    serviceHighlights:
      findNodeByLocale<SettingServiceHighlights>(allServiceHighlights),
  };
};
