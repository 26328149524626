import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import React from "react";
import tw from "twin.macro";
import CartDonationItemCard from "./CartDonationItemCard";
import {
  CartDonationItemsOutputProps,
  withCartDonationItems,
} from "./withCartDonationItems";

const Wrapper = styledWithInvalidProps("div")(({ layout }) => [
  layout === "cart"
    ? tw`w-full px-0 mb-maxi`
    : tw`w-full px-0 border-b mt-midi border-neutral mb-midi`,
]);

const Title = styledWithInvalidProps("p")(({ layout }) => [
  layout === "cart"
    ? tw`text-h4 leading-h4 mb-midi font-medium md:mb-maxi`
    : tw`w-full font-medium text-body mb-mini`,
]);

const Card = tw(CartDonationItemCard)``;

export const CartDonationItems = ({
  layout,
  items,
}: CartDonationItemsOutputProps) => {
  return (
    <Wrapper layout={layout} data-testid={"donation-items-wrapper"}>
      <Title data-testid={"donation-items-title"} layout={layout}>
        Donation Items:
      </Title>
      {items?.map((item, index) => (
        <Card key={index} item={item} layout="cart" />
      ))}
    </Wrapper>
  );
};

export default withCartDonationItems(CartDonationItems);
