import React, { memo } from "react";
import { GatsbyImage as Image, GatsbyImageProps } from "gatsby-plugin-image";
import { isEmpty } from "lodash";
import { ClickableImageWrapper } from "../Styled/Image";
import { ImageProps } from "./withImage";

export const GatsbyImage = memo(
  ({
    className,
    alt,
    onClick,
    ratio,
    image,
    fullHeight,
    imageLink,
    critical,
    staticImage = false,
  }: ImageProps) => {
    const isGif = image?.asset?.mimeType === "image/gif" ? true : false;
    const normalisedImage = isGif
      ? { ...image, srcSetWebp: null, srcWebp: null }
      : image;
    const isClickable =
      onClick && typeof onClick === "function" && !isEmpty(imageLink);
    const aspectRatio =
      Number(ratio?.split(`/`)[0]) / Number(ratio?.split(`/`)[1]);
    const imageProps: GatsbyImageProps = {
      alt,
      className,
      image: {
        ...normalisedImage,
        aspectRatio: !isNaN(aspectRatio) ? aspectRatio : undefined,
      },
      style: {
        height: fullHeight && "100%",
      },
      loading: critical ? "eager" : "lazy",
    };

    return (
      <ClickableImageWrapper
        as={isClickable && "a"}
        onClick={isClickable ? () => onClick(imageLink) : null}
      >
        {staticImage ? (
          <div
            style={{
              backgroundImage: `url(${image?.src})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: fullHeight ? "100%" : "auto",
            }}
          />
        ) : (
          <Image {...imageProps} />
        )}
      </ClickableImageWrapper>
    );
  },
);
