import { useAnalytics } from "@hooks/useAnalytics";
import { useApp } from "@hooks/useApp";
import { useSanitySearch } from "@hooks/useSanitySearch";
import { ComponentProps } from "@ts/components";
import { SearchResultSanity } from "@ts/search";
import { useSearch } from "@usereactify/search";
import { useDebounce } from "@react-hooks-library/core";
import React from "react";
import { FC, useEffect } from "react";

type SearchFormInputProps = ComponentProps;

type SearchFormOutputProps = {
  activeSearch: boolean;
  sanitySearchResults: SearchResultSanity[];
  onSearchClose: () => void;
  searchTerm: string;
};

export const withSearchForm =
  (Component: FC<SearchFormOutputProps>) =>
  ({ name = "SearchForm" }: SearchFormInputProps) => {
    const { globalStateReducer } = useApp();
    const { searchTerm, setSearchTerm } = useSearch();
    const { trackSearch } = useAnalytics();
    const { sanitySearchResults } = useSanitySearch();
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);
    const [{ activeSearch }, dispatch] = globalStateReducer;

    const handleSearchClose = () => {
      dispatch({
        type: "setActiveSearch",
        payload: !activeSearch,
      });
    };

    useEffect(() => {
      trackSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
      if (activeSearch) {
        setSearchTerm(undefined);
        document
          .querySelector<HTMLInputElement>(".search-input-wrapper input")
          ?.focus();
      }
    }, [activeSearch]);

    Component.displayName = name;

    return (
      <Component
        activeSearch={activeSearch}
        onSearchClose={handleSearchClose}
        sanitySearchResults={sanitySearchResults}
        searchTerm={searchTerm}
      />
    );
  };
