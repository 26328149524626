import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { Image } from "@components/Image/Image";
import { ImageWrapper } from "@components/Styled/ImageWrapper";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import React from "react";
import tw, { styled } from "twin.macro";
import { CartLineItemPrice } from "../LineItems/CartLineItemPrice";
import {
  CartDonationItemCardOutputProps,
  withCartDonationItemCard,
} from "./withCartDonationItemCard";

const Wrapper = styledWithInvalidProps("div")(({ layout }) => [
  tw`flex flex-wrap w-full bg-white relative`,
  layout === `cart` ? tw`p-midi` : tw`py-maxi px-midi md:p-mini`,
]);

const Title = styledWithInvalidProps("span")(({ layout }) => [
  tw`inline`,
  layout === "cart" ? tw`` : tw`md:pr-mega md:mb-mini`,
]);

const Text = styledWithInvalidProps("p")(({ bold, content }) => [
  tw`inline leading-caption text-hint md:text-body`,
  bold ? tw`font-medium` : "",
  !content ? tw`capitalize` : "",
]);

const StyledPrice = styledWithInvalidProps(CartLineItemPrice)(({ layout }) => [
  tw`text-hint md:text-body`,
  layout === "cart" ? tw`mt-pico mb-midi` : tw`leading-caption`,
]);

const StyledButton = withFocusRing(styled.button`
  ${tw`underline leading-caption w-full text-right hover:no-underline md:w-auto md:mb-mega`}
  ${({ disabled }) => (disabled ? tw`no-underline cursor-not-allowed` : "")}
`);

const ItemImageWrapper = tw(ImageWrapper)`
  mr-midi w-peta
`;

const Mobile = tw.div`
  w-full flex flex-col md:hidden
`;

const InfosWrapper = tw.div`
  flex-1 flex flex-col md:justify-between
`;
export const CartDonationItemCard = ({
  cartLoading,
  donationItem: { image, title, price, description, selected },
  handleSelect,
  layout,
}: CartDonationItemCardOutputProps) => {
  return (
    <Wrapper layout={layout} data-testid={"donation-wrapper"}>
      <ItemImageWrapper image={image}>
        <Image image={image} ratio="1/1" maxWidth={150} />
      </ItemImageWrapper>
      <InfosWrapper>
        <Title layout={layout} data-testid={"donation-info-title"}>
          <Text bold>{title}</Text>
          {description && description !== "" ? (
            <Text content>{` - ${description}`}</Text>
          ) : null}
        </Title>
        {layout === "cart" && price ? (
          <StyledPrice price={price} layout={layout} />
        ) : null}
        {layout === "drawer" && price ? (
          <Mobile>
            <StyledPrice price={price} layout={layout} />
          </Mobile>
        ) : null}
      </InfosWrapper>
      <StyledButton
        onClick={handleSelect}
        disabled={cartLoading || selected}
        data-testid={"donation-submit-button"}
      >
        <span>{cartLoading ? `Adding` : selected ? `Added` : `Donate`}</span>
      </StyledButton>
    </Wrapper>
  );
};

export default withCartDonationItemCard(CartDonationItemCard);
