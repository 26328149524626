import React from "react";
import { Script } from "gatsby";

import { useLiveChat } from "~/hooks/useLiveChat";

export function LiveChatScripts() {
  const { enabled, key } = useLiveChat();

  if (!enabled) return null;

  return (
    <Script
      id="ze-snippet"
      strategy="idle"
      onLoad={handleZendeskLoad}
      src={`https://static.zdassets.com/ekr/snippet.js?key=${key}`}
    />
  );
}

/**
 * @todo Remove when open calls migrated to hook.
 */
function handleZendeskLoad() {
  window["LiveChat"] = {
    open: () => {
      if (window["zE"]) {
        window["zE"]("messenger", "open");
      }
    },
  };
}

declare global {
  interface Window {
    /** @todo Remove */
    LiveChat?: any;
    /**
     * Attached to window by Zendesk live chat script.
     * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core
     */
    zE?: (
      arg1: "messenger",
      action: "show" | "hide" | "open" | "close",
    ) => void;
  }
}
