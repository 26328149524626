import { SanityTag } from "@ts/components";
import {
  ProductUpsell,
  SanityKeyed,
  Product as SanityProduct,
} from "@ts/sanity";
import { Product } from "@ts/shopify-storefront";
import { useCallback, useEffect, useState } from "react";
import { useApp } from "./useApp";
import { useCheckoutContext } from "./useCheckout";
import { useCore } from "./useCore";
import { useLocalisationContext } from "./useLocalisation";
import { usePage } from "./usePage";
import { useShopify } from "./useShopify";

export type UseUpsell = {
  enabled: boolean;
  upsells: Product[];
  title?: string;
  getUpsellsForCart: () => SanityKeyed<ProductUpsell>[];
};

export const useUpsell = (): UseUpsell => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp();
  const {
    graphql: {
      queries: { GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore();
  const { contextCountry } = useLocalisationContext();
  const { cart } = usePage();
  const { checkout } = useCheckoutContext();
  const { useLazyQuery, productNormaliser } = useShopify();
  const [upsells, setUpsells] = useState<Product[]>([]);
  const [matchingHandles, setMatchingHandles] = useState<string[]>([]);
  const {
    upsellsEnabled: enabled,
    upsellsProducts,
    upsellsTitle: title,
    upsellsMaximum,
  } = cart;
  const { lineItems } = checkout || {};
  const cartProducts = lineItems?.map(({ variant: { product } }) => product);

  const [getLiveProducts, { data, loading }] = useLazyQuery(
    GET_PRODUCTS_BY_HANDLE(matchingHandles),
    {
      variables: {
        countryCode: contextCountry,
        firstCollections: 1,
        firstMedia: 1,
        firstImages: 1,
        firstMetafields: 1,
        firstVariants: 100,
      },
    },
  );

  const getUpsellsForCart = useCallback(() => {
    if (!enabled || !upsellsProducts || !cartProducts) {
      return [];
    }

    return upsellsProducts?.filter(({ tags }) => {
      return tags?.find((tag: SanityTag) => {
        return cartProducts?.find((product: Product) => {
          return product?.tags?.find(
            (productTag: string) => productTag === tag?.value,
          );
        });
      });
    });
  }, [lineItems, upsellsProducts]);

  const getMatchingUpsells = useCallback(async () => {
    const cartProductHandles: string[] = cartProducts?.map(
      ({ handle }) => handle,
    );
    const matchingUpsells = getUpsellsForCart();
    const matchingHandles = matchingUpsells
      ?.reduce((prev: string[], curr: SanityKeyed<ProductUpsell>) => {
        const products = curr?.products as unknown as SanityProduct[];
        const handles = products
          ?.filter(({ shopify }) => shopify?.shopifyActive)
          .map(({ shopify }) => shopify?.shopifyHandle);
        return [...prev, ...handles];
      }, [])
      ?.filter((handle) => !cartProductHandles.includes(handle));

    setMatchingHandles(matchingHandles);

    if (!matchingHandles?.length) {
      setUpsells([]);
    }
  }, [checkout, setUpsells]);

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    const results = Object.keys(data).map((key) =>
      productNormaliser(data[key]),
    );
    const productAvailableForSale = results?.filter(
      ({ availableForSale }) => availableForSale,
    );
    const normalisedProducts = productAvailableForSale?.map((product) => {
      const normalisedProduct = productNormaliser(product);
      const variants = normalisedProduct?.variants?.filter(
        ({ availableForSale }) => availableForSale,
      );
      return {
        ...normalisedProduct,
        variants,
        link: `${routes.PRODUCT}/${normalisedProduct.handle}`,
        singleVariant: normalisedProduct?.variants?.length === 1,
      };
    });

    setUpsells(normalisedProducts?.slice(0, upsellsMaximum));
  }, [loading, data]);

  useEffect(() => {
    if (!matchingHandles?.length) {
      return;
    }

    getLiveProducts();
  }, [matchingHandles]);

  useEffect(() => {
    getMatchingUpsells();
  }, [checkout]);

  return {
    enabled,
    title,
    upsells,
    getUpsellsForCart,
  };
};
