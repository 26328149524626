import React from "react";
import tw, { styled } from "twin.macro";

import ModalsPopup from "../../Modals/ModalsPopup";
import FormInput from "../../Form/FormInput";
import { StyledButton } from "../../Styled/Button";
import { withBackInStock, BackInStockOutputProps } from "./withBackInStock";

const Content = tw.div`
  pt-mega pb-giga
`;

const Form = tw.form`
  flex flex-col items-center justify-center 
`;

const Title = styled.h2`
  ${tw`text-h2 md:text-[44px] font-display`}
  ${({ submited }) => (submited ? tw`text-center mb-mini` : tw`mb-maxi`)}
`;

const Description = styled.p`
  ${tw`max-w-[272px] mx-auto text-center leading-caption`}
  ${({ submited }) => (submited ? tw`mb-mini` : tw`mb-mega`)}
`;

const Input = tw(FormInput)`
  px-0 mb-mega
`;

const Submit = tw(StyledButton)`
  w-full max-w-[190px]
`;

const ErrorMessage = tw.div`
  text-caption mt-mini text-warning text-center font-medium max-w-[272px]
`;

const BackInStock = withBackInStock(
  ({
    loading,
    sent,
    errors,
    email,
    setEmail,
    handleSubmit,
    active,
    setActive,
  }: BackInStockOutputProps) => {
    return (
      <ModalsPopup layout="notify" active={active} setActive={setActive}>
        <Content>
          {!sent ? (
            <>
              <Form onSubmit={handleSubmit}>
                <Title>Notify me</Title>
                <Description>
                  Enter your email below and you will receive an email once this
                  item is back in stock.
                </Description>
                <Input
                  tpye="email"
                  name="email"
                  placeholder="Email"
                  placeholderColor="dark"
                  value={email}
                  handleChange={({ target: { value } }) => setEmail(value)}
                  required
                />
                <Submit
                  type="submit"
                  height="56"
                  textSize="16"
                  colour="lightest"
                  border
                  disabled={loading}
                >
                  Send
                </Submit>
              </Form>
              {errors?.length > 0 &&
                errors?.map((item, index) => (
                  <ErrorMessage key={index}>
                    {item?.message === "Request failed with status code 400"
                      ? "Email is invalid"
                      : item?.message}
                  </ErrorMessage>
                ))}
            </>
          ) : (
            <>
              <Title submited>Done!</Title>
              <Description submited>
                You’ll receive an email once this item is back in stock.
              </Description>
            </>
          )}
        </Content>
      </ModalsPopup>
    );
  },
);

export default BackInStock;
