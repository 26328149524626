import { useAtomValue } from "jotai";
import { useState, useEffect, useCallback } from "react";
import type { ClientResponse } from "@shopify/storefront-api-client";

import { storefrontAtom } from "~/atoms/storefront";

/**
 * Return storefront client associated with current site.
 * @see https://shopify.dev/docs/api/hydrogen/2024-07/utilities/createstorefrontclient
 */
export function useStorefront() {
  return useAtomValue(storefrontAtom);
}

/**
 * Execute the given storefront query and return props similar to react-query.
 * @todo Make query required when unit tests are binned as they are only undefined during unit tests.
 */
export function useStorefrontQuery(
  query: string | null | undefined,
  options: { variables?: Record<string, string | number | boolean> } = {},
) {
  const { variables = {} } = options;

  const storefront = useStorefront();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ClientResponse<any> | null>(null);

  useEffect(() => {
    if (!query) return;

    storefront
      .request(query, { variables })
      .then((res) => setData(res.data))
      .finally(() => setLoading(false));
  }, [query, variables, storefront]);

  return { data, loading };
}

/**
 * Return a function to execute the given mutation.
 * @todo Make query required when unit tests are binned as they are only undefined during unit tests.
 */
export function useStorefrontMutation(query: string | null | undefined) {
  const storefront = useStorefront();

  return useCallback(
    (options: { variables?: Record<string, string> } = {}) => {
      if (!query) return;
      const { variables = {} } = options;
      return storefront.request(query, { variables });
    },
    [query, storefront],
  );
}
