import React from "react";
import tw from "twin.macro";
import {
  FooterCopyrightOutputProps,
  withFooterCopyright,
} from "./withFooterCopyright";

const Copyright = tw.p`
  text-caption leading-triple text-left px-midi md:px-mini pb-maxi md:pb-0
`;

export const FooterCopyright = withFooterCopyright(
  ({ copyright }: FooterCopyrightOutputProps) => (
    <Copyright>Copyright © {copyright}</Copyright>
  ),
);
