import React from "react";
import tw, { styled } from "twin.macro";
import { withCartWidget } from "./withCartWidget";
import { Icon } from "@components/Icon";
import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";

const StyledCart = withFocusRing(tw.button`
  flex relative
`);

const StyledIcon = tw(Icon)`
  p-micro transition-presentation duration-slow text-secondary hover:text-primary md:px-mini md:py-maxi
`;
// DEBUG: Adding pb-1 here may be working on localhost but will cause styling issue on live site due to inconsistent font-family
const Quantity = styled.p`
  ${tw`text-caption font-bold absolute top-1/2 left-1/2 text-white transition-layout`}
  transform: translate(-50%, -30%);
`;

export const CartWidget = withCartWidget(({ quantity, handleClick }) => {
  return (
    <StyledCart
      className="group"
      onClick={handleClick}
      data-testid="cart-drawer-trigger"
    >
      <StyledIcon name={quantity > 0 ? `bagActive` : `bag`} size="xsmall" />
      <Quantity>{quantity || "0"}</Quantity>
    </StyledCart>
  );
});
