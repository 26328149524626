import React from "react";
import tw from "twin.macro";

import { FooterTerms } from "./FooterTerms";
import { FooterCopyright } from "./FooterCopyright";
import { FooterAchievements } from "./FooterAchievements";
import NavigationFooter from "../Navigation/NavigationFooter";
import { FooterAcknowledgementOfCountry } from "./FooterAcknowledgementOfCountry";

const Row = tw.div`
  flex flex-col justify-between w-full flex-nowrap
`;

const ReverseRow = tw.div`
  flex flex-col max-w-xl md:flex-row
`;

export function FooterContent() {
  return (
    <>
      <Row>
        <NavigationFooter />
      </Row>
      <FooterAchievements />
      <FooterAcknowledgementOfCountry />
      <ReverseRow>
        <FooterTerms />
        <FooterCopyright />
      </ReverseRow>
    </>
  );
}
