import { Search } from "@usereactify/search";
import React from "react";
import tw, { styled } from "twin.macro";
import { Icon } from "../../Icon";
import { SearchFormResults } from "./SearchFormResults";
import { withSearchForm } from "./withSearchForm";

const Wrapper = styled.div`
  ${tw`relative h-full`}
`;

const InputWrapper = styled.div`
  ${tw`relative flex-1`}
  input {
    ${tw`w-full text-h4 text-left text-primary leading-input px-mega h-giga py-zero border-0 outline-none bg-transparent md:text-center md:text-[32px] md:h-[86px] focus:outline-none`}
    font-family: Harriet Display,sans-serif !important;
  }
`;

const StyledDesktopIcon = tw(
  Icon,
)`hidden transition-layout -mx-micro text-primary py-micro lg:px-mini md:block`;

const StyledMobileIcon = tw(
  Icon,
)`block transition-layout py-micro -mx-micro text-primary lg:px-mini md:hidden`;

const CloseIconWrapper = styled.div`
  ${tw`absolute cursor-pointer right-mini top-[13px] md:top-midi md:right-midi`}
  ${({ display }) => (display === "true" ? tw`z-20` : "")}
`;

const SearchIconWrapper = styled.div`
  ${tw`absolute cursor-pointer left-mini top-[13px] md:top-midi md:left-midi`}
  ${({ display }) => (display === "true" ? tw`z-20` : "")}
`;

export const SearchForm = withSearchForm(
  ({ activeSearch, onSearchClose, searchTerm, sanitySearchResults }) => {
    return (
      <Wrapper>
        <InputWrapper className="search-input-wrapper">
          <Search />
          <CloseIconWrapper
            onClick={onSearchClose}
            display={searchTerm?.length > 0 ? "false" : "true"}
            data-testid="header-search-close-trigger"
          >
            <StyledDesktopIcon name="cross" size="small" />
            <StyledMobileIcon name="cross" size="xsmall" />
          </CloseIconWrapper>
          <SearchIconWrapper>
            <StyledDesktopIcon name="searchThin" size="small" />
            <StyledMobileIcon name="searchThin" size="xsmall" />
          </SearchIconWrapper>
          <SearchFormResults
            active={activeSearch}
            sanitySearchResults={sanitySearchResults}
          />
        </InputWrapper>
      </Wrapper>
    );
  },
);
