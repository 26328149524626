import { NormalisedCheckout } from "@ts/components";
import { Checkout } from "@ts/shopify-storefront";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

type CheckoutContext = {
  checkout?: NormalisedCheckout;
  rawCheckout?: Checkout;
  setCheckout?: Dispatch<SetStateAction<NormalisedCheckout>>;
  setRawCheckout?: Dispatch<SetStateAction<Checkout>>;
};

export const CheckoutContext = React.createContext<CheckoutContext>({});

export const CheckoutProvider = ({ children }) => {
  const [checkout, setCheckout] = useState<NormalisedCheckout>();
  const [rawCheckout, setRawCheckout] = useState<Checkout>();

  const values = useMemo(
    () => ({
      checkout,
      rawCheckout,
      setCheckout,
      setRawCheckout,
    }),
    [checkout, rawCheckout, setCheckout, setRawCheckout],
  );

  return (
    <CheckoutContext.Provider value={values}>
      {children}
    </CheckoutContext.Provider>
  );
};
