import { css, Global } from "@emotion/react";
import React from "react";
import "swiper/css/bundle";
import tw, { GlobalStyles } from "twin.macro";

const AppStyles = () => {
  return (
    <>
      <GlobalStyles />
      <Global
        styles={css`
          html {
            scrollbar-color: #aba49c #f4f1ee;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            -ms-overflow-style: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            /* Chrome, Edge and Safari */
            ::-webkit-scrollbar {
              height: 4px;
              width: 4px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 0px;
              background-color: #f4f1ee;
            }

            ::-webkit-scrollbar-track:hover {
              background-color: #f4f1ee;
            }

            ::-webkit-scrollbar-track:active {
              background-color: #f4f1ee;
            }

            ::-webkit-scrollbar-thumb {
              border-radius: 0px;
              background-color: #aba49c;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: #aba49c;
            }

            ::-webkit-scrollbar-thumb:active {
              background-color: #aba49c;
            }
          }

          input[type="search"]::-webkit-search-decoration,
          input[type="search"]::-webkit-search-cancel-button,
          input[type="search"]::-webkit-search-results-button,
          input[type="search"]::-webkit-search-results-decoration {
            -webkit-appearance: none;
          }

          input {
            border-radius: 0;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }

          #gatsby-focus-wrapper {
            ${tw`flex flex-col`}
          }

          .underlined-white {
            span {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                background: #fff;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 1px;
              }
            }
          }
          .underlined-black {
            span {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                background: #000;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 1px;
              }
            }
            &:hover {
              span:after {
                background: #ba5c27;
              }
            }
          }

          .hide-scrollbar {
            -ms-overflow-style: none; /* IE, Edge */
            scrollbar-width: none;
            scrollbar-color: transparent transparent;
          }

          .hide-scrollbar::-webkit-scrollbar {
            display: none;
            width: 0px; /* Remove scrollbar space */
            height: 0px; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
            -webkit-appearance: none;
          }

          .filter-range-label {
            color: #2b2b2b;
            :hover {
              text-decoration: underline;
              color: #2b2b2b !important;
            }
          }
          .filter-range-list {
            li.active {
              label {
                text-decoration: underline;
                color: #2b2b2b !important;
                font-weight: normal;
              }
            }
          }

          .react-tel-input .flag-dropdown.open .selected-flag {
            background-color: transparent !important;
          }
          .react-tel-input .selected-flag:hover,
          .react-tel-input .selected-flag:focus {
            background-color: transparent !important;
          }

          #inside_tabs {
            transition-property: all !important;
            transition-duration: 500ms !important;
            @media only screen and (max-width: 767px) {
              bottom: 50px !important;
            }
          }

          .ruk-icon-percentage-star--0,
          .ruk-icon-percentage-star--25,
          .ruk-icon-percentage-star--50,
          .ruk-icon-percentage-star--75,
          .ruk-icon-percentage-star--100 {
            font-size: 0.75rem !important;
          }

          .ruk_rating_snippet {
            color: currentColor !important;
            line-height: 0.5 !important;
          }

          .ruk-rating-snippet-count {
            color: currentColor !important;
            font-size: 0.875rem !important;
            line-height: 1.2 !important;
          }

          .hide-count > .ruk-rating-snippet-count {
            display: none !important;
          }

          .ruk_rating_snippet--clp i {
            font-size: 0.75rem !important;
            line-height: 1.1 !important;

            @media only screen and (min-width: 768px) {
              line-height: 1.1 !important;
              font-size: 0.75rem !important;
            }
          }

          .ruk_rating_snippet--clp span {
            font-size: 0.75rem !important;

            @media only screen and (min-width: 768px) {
              font-size: 0.75rem !important;
            }
          }

          [data-id="search-results-list"] .ruk-rating-snippet-count {
            display: none;
          }

          .swiper-container {
            width: 100%;
          }
          .swiper-horizontal
            > .swiper-pagination-bullets
            .swiper-pagination-bullet {
            margin: 0 8px !important;
          }
          .swiper-pagination-bullet-active {
            background-color: #76695d !important;
          }
          .swiper-vertical > .swiper-scrollbar {
            top: 0 !important;
            height: 100% !important;
          }
          .swiper-horizontal > .swiper-scrollbar {
            border-radius: 0px;
            bottom: 0 !important;
            left: 0 !important;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
            position: relative !important;
            width: 50% !important;
          }
          .swiper-scrollbar-drag {
            background: rgb(118, 105, 93) !important;
            border-radius: 0px !important;
          }
          .swiper-button-disabled {
            opacity: 0.5;
            cursor: default;
          }

          //Scrollbar Styling
          /* Firefox */
          * {
            scrollbar-width: thin;
          }

          // These classes are here because
          // a) The safelist with hidden tokens is too large to deploy to SSR
          // b) Tailwind will not generate classes if they're not used in components
          // c) We need time to update all the classes in sanity

          .migration-classes-do-not-use {
            ${tw`text-light text-lighter text-lightest text-dark-grey text-warm-grey text-light-green text-beige text-dark text-green text-scorpion text-charcoal`}
            ${tw`bg-light bg-lighter bg-lightest bg-dark-grey bg-warm-grey bg-light-green bg-beige bg-dark bg-green bg-scorpion bg-charcoal`}
            ${tw`border-light border-lighter border-lightest border-dark-grey border-warm-grey border-light-green border-beige border-dark border-green border-scorpion border-charcoal`}
            ${tw`p-1 p-2 p-4 p-8 p-12 p-2-2 p-1-1 p-1-2 p-1-4 p-1-6 p-1-8 p-2-4 p-3-2 p-4-8 p-6-4 p-12-2 p-12-3 p-12-8`}
            ${tw`px-1 px-2 px-4 px-8 px-12 px-2-2 px-1-1 px-1-2 px-1-4 px-1-6 px-1-8 px-2-4 px-3-2 px-4-8 px-6-4 px-12-2 px-12-3 px-12-8`}
            ${tw`py-1 py-2 py-4 py-8 py-12 py-2-2 py-1-1 py-1-2 py-1-4 py-1-6 py-1-8 py-2-4 py-3-2 py-4-8 py-6-4 py-12-2 py-12-3 py-12-8`}
            ${tw`pt-1 pt-2 pt-4 pt-8 pt-12 pt-2-2 pt-1-1 pt-1-2 pt-1-4 pt-1-6 pt-1-8 pt-2-4 pt-3-2 pt-4-8 pt-6-4 pt-12-2 pt-12-3 pt-12-8`}
            ${tw`pr-1 pr-2 pr-4 pr-8 pr-12 pr-2-2 pr-1-1 pr-1-2 pr-1-4 pr-1-6 pr-1-8 pr-2-4 pr-3-2 pr-4-8 pr-6-4 pr-12-2 pr-12-3 pr-12-8`}
            ${tw`pb-1 pb-2 pb-4 pb-8 pb-12 pb-2-2 pb-1-1 pb-1-2 pb-1-4 pb-1-6 pb-1-8 pb-2-4 pb-3-2 pb-4-8 pb-6-4 pb-12-2 pb-12-3 pb-12-8`}
            ${tw`pl-1 pl-2 pl-4 pl-8 pl-12 pl-2-2 pl-1-1 pl-1-2 pl-1-4 pl-1-6 pl-1-8 pl-2-4 pl-3-2 pl-4-8 pl-6-4 pl-12-2 pl-12-3 pl-12-8`}
            ${tw`m-1 m-2 m-4 m-8 m-12 m-2-2 m-1-1 m-1-2 m-1-4 m-1-6 m-1-8 m-2-4 m-3-2 m-4-8 m-6-4 m-12-2 m-12-3 m-12-8`}
            ${tw`mx-1 mx-2 mx-4 mx-8 mx-12 mx-2-2 mx-1-1 mx-1-2 mx-1-4 mx-1-6 mx-1-8 mx-2-4 mx-3-2 mx-4-8 mx-6-4 mx-12-2 mx-12-3 mx-12-8`}
            ${tw`my-1 my-2 my-4 my-8 my-12 my-2-2 my-1-1 my-1-2 my-1-4 my-1-6 my-1-8 my-2-4 my-3-2 my-4-8 my-6-4 my-12-2 my-12-3 my-12-8`}
            ${tw`mt-1 mt-2 mt-4 mt-8 mt-12 mt-2-2 mt-1-1 mt-1-2 mt-1-4 mt-1-6 mt-1-8 mt-2-4 mt-3-2 mt-4-8 mt-6-4 mt-12-2 mt-12-3 mt-12-8`}
            ${tw`mr-1 mr-2 mr-4 mr-8 mr-12 mr-2-2 mr-1-1 mr-1-2 mr-1-4 mr-1-6 mr-1-8 mr-2-4 mr-3-2 mr-4-8 mr-6-4 mr-12-2 mr-12-3 mr-12-8`}
            ${tw`mb-1 mb-2 mb-4 mb-8 mb-12 mb-2-2 mb-1-1 mb-1-2 mb-1-4 mb-1-6 mb-1-8 mb-2-4 mb-3-2 mb-4-8 mb-6-4 mb-12-2 mb-12-3 mb-12-8`}
            ${tw`ml-1 ml-2 ml-4 ml-8 ml-12 ml-2-2 ml-1-1 ml-1-2 ml-1-4 ml-1-6 ml-1-8 ml-2-4 ml-3-2 ml-4-8 ml-6-4 ml-12-2 ml-12-3 ml-12-8`}
          }
        `}
      />
    </>
  );
};

export default AppStyles;
