import React, { FC } from "react";
import { useSettings } from "../../hooks/useSettings";
import { ComponentProps } from "../../types/components";

export type FooterCopyrightInputProps = ComponentProps;

export type FooterCopyrightOutputProps = {
  copyright: string;
};

export const withFooterCopyright =
  (Component: FC<FooterCopyrightOutputProps>) =>
  ({ name = "FooterCopyright" }: FooterCopyrightInputProps) => {
    const { copyright: settings } = useSettings();
    const text = settings?.copyright;

    if (!text) {
      return null;
    }

    Component.displayName = name;
    return <Component copyright={text} />;
  };
