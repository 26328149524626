import { StyledButton } from "@components/Styled/Button";
import React from "react";
import tw from "twin.macro";
import BackInStock from "../BackInStock/BackInStock";
import AddToCartErrorPopup from "./AddToCartErrorPopup";
import { withAddToCart } from "./withAddToCart";

const Wrapper = tw.div``;

const Button = tw(StyledButton)`
  w-full
`;

const AddToCart = withAddToCart(
  ({
    "data-testid": dataTestId,
    active,
    additionalAddingToCart,
    additionalAddToCart,
    additionalNotify,
    additionalOutOfStock,
    cart,
    className,
    errorActive,
    loading,
    notify,
    onAddToCart,
    selectedVariant,
    setActive,
    setErrorActive,
    wishlist,
    upsell,
  }) => {
    const buttonHeight = wishlist ? "48" : upsell || cart ? "40" : "56";
    const buttonColour = upsell ? "transparent" : "primary";
    const buttonTextSize = cart ? "14" : upsell ? "12" : "16";

    return (
      <>
        <Wrapper className={className}>
          {!selectedVariant ? (
            <Button
              data-testid={dataTestId ? `${dataTestId}-no-size` : null}
              height={buttonHeight}
              textSize={buttonTextSize}
              colour={buttonColour}
              onClick={() => setErrorActive(true)}
            >
              {additionalAddToCart || "Add to cart"}
            </Button>
          ) : selectedVariant?.availableForSale ? (
            <Button
              height={buttonHeight}
              textSize={buttonTextSize}
              colour={buttonColour}
              onClick={() => onAddToCart(selectedVariant?.id)}
              disabled={loading}
              data-testid={dataTestId ? `${dataTestId}-size-selected` : null}
              border
            >
              {loading
                ? additionalAddingToCart || "Adding"
                : upsell
                  ? "Quick Add"
                  : selectedVariant?.isPreOrder
                    ? "Pre-Order Now"
                    : additionalAddToCart || "Add to cart"}
            </Button>
          ) : notify ? (
            <Button
              data-testid={dataTestId ? `${dataTestId}-notify-me` : null}
              height={buttonHeight}
              textSize={buttonTextSize}
              colour={buttonColour}
              onClick={() => setActive(true)}
            >
              {additionalNotify || "Email me when restocked"}
            </Button>
          ) : (
            <Button
              data-testid={dataTestId ? `${dataTestId}-out-of-stock` : null}
              height={buttonHeight}
              textSize={buttonTextSize}
              colour={buttonColour}
            >
              {additionalOutOfStock || "Out of stock"}
            </Button>
          )}
        </Wrapper>
        <BackInStock
          selectedVariant={selectedVariant}
          active={active}
          setActive={setActive}
        />
        <AddToCartErrorPopup active={errorActive} setActive={setErrorActive} />
      </>
    );
  },
);

export default AddToCart;
