import tw, { styled } from "twin.macro";

export const styles = {
  H1: tw`font-display text-h1 leading-h1 xl:text-[56px]`,
  H2: tw`font-display text-h2 leading-h2 md:text-[32px] xl:text-[44px]`,
  H3: tw`font-display text-h4 leading-h3 md:text-[32px]`,
  H4: tw`font-body text-h3 leading-h4 font-medium`,
  H5: tw`font-body text-hint leading-h5 tracking-heading uppercase pb-pico`,
  P: tw`font-body text-body leading-body`,
  Small: tw`font-body text-hint leading-hint`,
  XSmall: tw`font-body text-caption leading-hint`,
  Underline: tw`block border-b pb-nano`,
  UnderlineDisabled: tw`block pb-nano`,
  UnderlineSmall: tw`border-b pb-[1px] leading-body block`,
  UnderlineSmallDisabled: tw`pb-[1px] leading-body block`,
};

export const StyledH1 = styled.h1`
  ${styles.H1}
`;
export const StyledH2 = styled.h2`
  ${styles.H2}
`;
export const StyledH3 = styled.h3`
  ${styles.H3}
`;
export const StyledH4 = styled.h4`
  ${styles.H4}
`;
export const StyledH5 = styled.h5`
  ${styles.H5}
`;

export const StyledP = styled.p`
  ${styles.P}
`;
export const StyledSmall = styled.p`
  ${styles.Small}
`;

export const StyledXSmall = styled.p`
  ${styles.XSmall}
`;

export const UnderlineText = styled.span`
  ${({ disabled }) => (disabled ? styles.UnderlineDisabled : styles.Underline)}
`;

export const UnderlineTextSmall = styled.span`
  ${({ disabled }) =>
    disabled ? styles.UnderlineSmallDisabled : styles.UnderlineSmall}
`;
