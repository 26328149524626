import { useApp } from "@hooks/useApp";
import { HistoryLocation } from "@reach/router";
import { ComponentProps } from "@ts/components";
import React, { FC, memo, useEffect, useMemo } from "react";

type HeaderAccountInputProps = ComponentProps & {
  location: HistoryLocation;
};

type HeaderAccountOutputProps = HeaderAccountInputProps & {
  activeSearch: boolean;
  handleClick: () => void;
};

export const withHeaderAccount = (Component: FC<HeaderAccountOutputProps>) =>
  memo(({ name = "HeaderAccount", location }: HeaderAccountInputProps) => {
    const { globalStateReducer } = useApp();
    const [{ activeSearch }, dispatch] = globalStateReducer;

    const handleClick = () => {
      dispatch({
        type: "setActiveSearch",
        payload: !activeSearch,
      });
    };

    useEffect(() => {
      const searchInput = document.querySelector<HTMLInputElement>(
        `input[aria-label="DesktopSearchForm"]`,
      );
      if (activeSearch && searchInput) {
        searchInput.select();
      }
    }, [activeSearch]);

    Component.displayName = name;
    return useMemo(
      () => (
        <Component
          activeSearch={false}
          handleClick={handleClick}
          location={location}
        />
      ),
      [activeSearch, handleClick, location],
    );
  });
