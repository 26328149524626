import { SettingAnnouncements } from "@ts/sanity";
import { graphql, useStaticQuery } from "gatsby";
import { useCallback, useState } from "react";
import { useApp } from "./useApp";
import { useCore } from "./useCore";
import { useLocalisation } from "./useLocalisation";

export const useAnnouncement = () => {
  const {
    helpers: { storage },
  } = useCore();

  const {
    config: {
      settings: { keys },
    },
  } = useApp();

  const { findNodeByLocale } = useLocalisation();

  const { announcements } = useStaticQuery(graphql`
    query SANITY_SETTINGS_ANNOUNCEMENTS {
      announcements: allSanitySettingAnnouncements {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            duration
            announcements {
              _type
              textLink {
                ...InternalLink
                ...Link
              }
              title
            }
          }
        }
      }
    }
  `);

  const [show, setShow] = useState(
    storage.get(keys?.announcement) !== "hidden",
  );
  const localisedAnnouncements =
    findNodeByLocale<SettingAnnouncements>(announcements);
  const items = localisedAnnouncements?.announcements || [];
  const duration = localisedAnnouncements?.duration || 5;

  const hide = useCallback(() => {
    storage.set(keys?.announcement, "hidden");
    setShow(false);
  }, [setShow]);

  return {
    items,
    duration,
    show,
    hide,
  };
};
