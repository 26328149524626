import { useMemo } from "react";
import { print } from "graphql";
import type { DocumentNode } from "@apollo/client";

import { useStorefrontQuery } from "./useStorefront";

/**
 * Execute the given storefront query against the new storefront API, but downgrade the data response
 * to match that of the "2023-07" API.

 * @deprecated Do not use this for new work, it is only for backwards compatibility.
 * @todo Make query required when unit tests are binned as they are only undefined during unit tests.
 */
export function useStorefrontLegacyQuery(
  _query: string | DocumentNode | null | undefined,
  options: { variables?: Record<string, string | number | boolean> } = {},
) {
  // Convert query to string if provided a gql tagged literal.
  const query = useMemo(
    () =>
      !!_query ? ("string" === typeof _query ? _query : print(_query)) : null,
    [_query],
  );

  // Memoize the variables because for some reason some components keep sending a new object.
  const variables = useMemo(
    () => options.variables,
    [JSON.stringify(options.variables)],
  );

  const { data, loading } = useStorefrontQuery(query, { variables });

  const downgradedData = useMemo(
    () => (data ? downgradeData(data) : null),
    [data],
  );

  return { data: downgradedData, loading };
}

/**
 * Receive data from the new Storefront API, downgrade to "2023-07" format.
 */
function downgradeData(data: any): any {
  return data;
}
