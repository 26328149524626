import Announcement from "@components/Announcement/Announcement";
import NavigationHeader from "@components/Navigation/NavigationHeader";
import HeaderTopBarMenu from "@components/Navigation/NavigationTopBar";
import { SearchForm } from "@components/Search/Form/SearchForm";
import {
  BackgroundContainer,
  StyledContainer,
} from "@components/Styled/Container";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import { ReviewsProvider } from "@providers/global/reviews";
import { ReactifySearchProvider, Sensors } from "@usereactify/search";
import React from "react";
import tw from "twin.macro";
import HeaderAccount from "./HeaderAccount";
import HeaderBrand from "./HeaderBrand";
import { withHeader } from "./withHeader";

import { useRouteLoaderData } from "~/hooks/useRouteLoaderData";

const BgContainer = tw(BackgroundContainer)`
  fixed top-0 z-50
`;

const SearchFormContainer = styledWithInvalidProps("div")(({ active }) => [
  tw`fixed w-full bg-white h-[60px] transition-layout duration-slow md:h-[86px] md:py-0`,
  active === `true` ? tw`z-100` : tw`hidden`,
]);

const HeaderContainer = styledWithInvalidProps("div")(({ active }) => [
  tw`fixed z-30 flex items-center w-full h-[60px] transition-opacity duration-slow bg-white md:h-[86px]`,
  active === `true` ? tw`opacity-100` : tw`opacity-0`,
]);

const Container = tw(StyledContainer)`
  relative flex flex-row justify-between max-w-2xl mx-auto md:min-h-[86px] px-mini xl:px-micro
`;
const TopBar = tw(StyledContainer)`
  relative flex flex-row justify-start max-w-2xl mx-auto text-caption leading-[2.57] bg-secondary text-white md:text-hint
`;
const StyledSearchForm = tw(SearchForm)`
  items-center w-full h-full xl:flex pr-mini
`;

const Header = withHeader(
  ({
    activeSearch,
    location,
    headerRef,
    topBarRef,
    navigationRootPath,
    handleRedirect,
  }) => {
    const { market } = useRouteLoaderData("routes/$site");

    return (
      <BgContainer background="lightest">
        <BackgroundContainer background="green">
          <TopBar width="full" ref={topBarRef}>
            <Announcement />
            <HeaderTopBarMenu />
          </TopBar>
        </BackgroundContainer>
        <SearchFormContainer
          active={activeSearch ? "true" : "false"}
          data-testid="header-search-container"
        >
          <Container width="full">
            {activeSearch ? (
              <ReviewsProvider>
                <ReactifySearchProvider
                  mode="instant-search"
                  /** @todo Work out which fields are actually required */
                  includeFields={["*"]}
                  market={market.legacyId}
                  shopifyPermanentDomain="bared-footwear-au.myshopify.com"
                  theme={{
                    colors: {
                      textColor: "rgb(43,43,43)",
                      primaryTextColor: "rgb(43,43,43)",
                      primaryColor: "rgb(43,43,43)",
                      titleColor: "rgb(43,43,43)",
                      alertColor: "rgb(43,43,43)",
                    },
                  }}
                  onRedirect={handleRedirect}
                >
                  <Sensors />
                  <StyledSearchForm />
                </ReactifySearchProvider>
              </ReviewsProvider>
            ) : null}
          </Container>
        </SearchFormContainer>
        <HeaderContainer
          ref={headerRef}
          active={!activeSearch ? "true" : "false"}
        >
          <Container width="full">
            <NavigationHeader
              location={location}
              navigationRootPath={navigationRootPath}
            />
            <HeaderBrand />
            <HeaderAccount location={location} />
          </Container>
        </HeaderContainer>
      </BgContainer>
    );
  },
);

export default Header;
