import { useAnalytics } from "@hooks/useAnalytics";
import { useApp } from "@hooks/useApp";
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout";
import { useFunctions } from "@hooks/useFunctions";
import { usePage } from "@hooks/usePage";
import { ComponentProps } from "@ts/components";
import { Internal, Product, SanityKeyedReference } from "@ts/sanity";
import { CheckoutLineItem } from "@ts/shopify-storefront";
import React, {
  FC,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useCustomer } from "~/hooks/useSession";

export type CartDrawerInputProps = ComponentProps;

export type CartDrawerOutputProps = {
  activeCart: boolean | { activeCart: boolean; newItemAdded: boolean };
  cartItemsQuantity: number;
  cartUrl: string;
  checkoutUrl: string;
  donationEnabled: boolean;
  donationProducts: SanityKeyedReference<Product>[];
  emptyTitle: string;
  handleCartActive: () => void;
  handleCheckout: (
    e: MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => Promise<void>;
  keepShopping: Internal;
  lineItemsAccessoryMap: Record<string, CheckoutLineItem[]>;
  lineItemsProducts: CheckoutLineItem[];
  loading: boolean;
  newItemAdded: boolean;
  title: string;
  totalItems: number;
};

export const withCartDrawer = (Component: FC<CartDrawerOutputProps>) =>
  memo(({ name = "CartDrawer" }: CartDrawerInputProps) => {
    const { checkoutUrl } = useCheckout();
    const { checkout } = useCheckoutContext();
    const { calculateLineItemsQuantityTotal } = useCheckout();
    const { loading, checkoutMultipass } = useFunctions();
    const { trackCartView } = useAnalytics();

    const customer = useCustomer();

    const {
      cart: {
        title,
        emptyTitle,
        keepShopping,
        donationEnabled,
        donationProducts,
      },
    } = usePage();

    const {
      config: {
        settings: { routes },
      },
      globalStateReducer,
    } = useApp();

    const [{ activeCart, newItemAdded }, dispatch] = globalStateReducer;
    const cartUrl = `${routes.CART}`;
    const { lineItems, lineItemsAccessoryMap, lineItemsProducts } =
      checkout || {};
    const quantity = calculateLineItemsQuantityTotal();
    const totalItems = lineItems?.length;

    const handleCartActive = useCallback(() => {
      dispatch({
        type: "setActiveCart",
        payload: !activeCart
          ? { activeCart: !activeCart, newItemAdded: false }
          : false,
      });
    }, [activeCart]);

    const handleCheckout = useCallback(
      async (e: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        await checkoutMultipass(customer?.email, checkout.id, checkoutUrl);
      },
      [customer, checkout, checkoutUrl, checkoutMultipass],
    );

    useEffect(() => {
      if (activeCart) trackCartView();
    }, [activeCart]);

    // Toggle data attribute used with CSS to show/hide elements which conflict with the cart drawer.
    React.useEffect(() => {
      document.documentElement.dataset.cartOpen = activeCart ? "true" : "false";
    }, [activeCart]);

    Component.displayName = name;

    return useMemo(
      () => (
        <Component
          activeCart={activeCart}
          cartItemsQuantity={quantity}
          cartUrl={cartUrl}
          checkoutUrl={checkoutUrl}
          donationEnabled={donationEnabled}
          donationProducts={donationProducts}
          emptyTitle={emptyTitle}
          handleCartActive={handleCartActive}
          handleCheckout={handleCheckout}
          lineItemsAccessoryMap={lineItemsAccessoryMap}
          lineItemsProducts={lineItemsProducts}
          keepShopping={keepShopping}
          loading={loading}
          newItemAdded={newItemAdded}
          title={title}
          totalItems={totalItems}
        />
      ),
      [
        activeCart,
        cartUrl,
        checkoutUrl,
        donationEnabled,
        donationProducts,
        emptyTitle,
        handleCartActive,
        keepShopping,
        lineItemsAccessoryMap,
        lineItemsProducts,
        loading,
        newItemAdded,
        quantity,
        title,
        totalItems,
      ],
    );
  });
