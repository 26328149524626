import { createClient } from "@sanity/client";
import React, { useMemo, createContext } from "react";

import type { SanityClient } from "@sanity/client";

export interface SanityContextType {
  sanityClient: SanityClient;
}

export const SanityContext = createContext<SanityContextType | null>(null);

export interface SanityProviderProps {
  children: React.ReactNode;
}

export function SanityProvider({ children }: SanityProviderProps) {
  /**
   * Create a Sanity client attached to the appropriate dataset.
   * @todo The dataset will change here in the future when dataset is provided from context.
   */
  const sanityClient = useMemo(
    () =>
      createClient({
        useCdn: true,
        dataset: "production",
        projectId: "z60zyrm2",
        apiVersion: "2023-06-01",
        perspective: "published",
      }),
    [],
  );

  const value: SanityContextType = { sanityClient };

  return (
    <SanityContext.Provider value={value}>{children}</SanityContext.Provider>
  );
}

SanityProvider.displayName = "SanityProvider";
