import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { Icon } from "@components/Icon";
import React from "react";
import Select, { components } from "react-select";
import tw, { styled } from "twin.macro";
import FormLabel from "./FormLabel";
import { withDropdown } from "./withDropdown";

const { DropdownIndicator, SingleValue } = components;

const HiddenInput = tw.input`
  h-zero w-zero opacity-0
`;

const SelectOptions = tw.div`
  w-full flex justify-between items-center 
`;

const Label = tw.span`mt-nano`;
const Content = tw.span`text-hint`;
const StyledSelect = withFocusRing(styled(Select)``);

const CustomDropdownIndicator = (props) => (
  <DropdownIndicator {...props}>
    <Icon name="arrowDropdown" size="xxs" />
  </DropdownIndicator>
);

const CustomSingleValue = ({ children, ...props }) => {
  return (
    <SingleValue {...props}>
      {children.props?.children[1] ? (
        <>
          {children.props?.children[0]} - {children.props?.children[1]}
        </>
      ) : (
        <>{children}</>
      )}
    </SingleValue>
  );
};

export const FormatOptionLabel = (option) => {
  let label = null;

  if (option.quantityAvailable < option.lowStockThreshold) {
    label = "Low stock";
  }

  if (option.isFinalSale) {
    label = "Final sale";
  }

  if (!option.available) {
    if (option.notify) {
      label = "Notify me";
    } else {
      label = "Sold out";
    }
  }

  if (option.isPreOrder) {
    label = "Pre-order";
  }

  return (
    <SelectOptions aria-disabled={option.isDisabled}>
      <Label aria-label={option.label}>{option.label}</Label>
      {label ? <Content>{label}</Content> : null}
    </SelectOptions>
  );
};

const ValueContainerStyles = {
  valueContainer: () => ({
    color: "#76695D",
    font: "inherit",
    WebkitOverflowScrolling: "touch",
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    overflow: "hidden",
    padding: 0,
    position: "relative",
  }),
};

const GeneralStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

const productStyles = {
  control: (provided, { selectProps, isFocused }) => ({
    width: "100%",
    height: "3rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    border:
      selectProps.name === "cartLineItem-sizeSelector"
        ? "1px solid #76695D"
        : "1px solid #968d84",
    borderRadius: 0,
    padding: "0 10px",
    font: "inherit",
    cursor: "pointer",
    whiteSpace: "break-spaces",
    outlineStyle: isFocused && "dashed",
    outlineWidth: isFocused && "1px",
    outlineOffset: isFocused && "1px",
    outlineColor: isFocused && "currentColor",
    "@media screen and (min-width: 26rem)": {
      ...provided["@media screen and (min-width: 26rem)"],
      padding: "0 15px",
    },
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",

    "& path": {
      stroke: "#76695D",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    lineHeight: "18.2px",
    padding: "2px 0",
  }),
  option: (provided, { isDisabled, isSelected, isFocused }) => ({
    ...provided,
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    border: "0.5px solid #968d84",
    borderBottom: "0",
    cursor: "pointer",
    backgroundColor: isDisabled
      ? "#e8e2dB"
      : isFocused
        ? "#968d84"
        : isSelected && "#2b2b2b",
    color: isDisabled
      ? "#968d84"
      : isFocused || isSelected
        ? "#ffffff"
        : "#76695D",
    transition: "background-color 0.05s ease-in-out, color 0.05s ease-in-out",

    "& span:first-child": {
      color: isDisabled
        ? "#2b2b2b"
        : isFocused || isSelected
          ? "#ffffff"
          : "#76695D",
    },

    "&:hover": {
      backgroundColor: isDisabled ? undefined : "#968d84",
      color: isDisabled ? undefined : "#ffffff",

      "& span:first-child": {
        color: isDisabled ? undefined : "#ffffff",
      },
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#76695D",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#76695D",
    position: "relative",
  }),
  menu: (provided, { selectProps }) => {
    return {
      ...provided,
      borderRadius: "0",
      font: "inherit",
      backgroundColor: "white",
      boxSizing: "border-box",
      position: "absolute",
      width: "100%",
      margin: "0",
      top: "auto",
      bottom:
        selectProps?.name === "addToCartFooter-sizeSelector" ? "100%" : "auto",
      zIndex: 20,
    };
  },
  menuList: () => ({
    paddingTop: "0",
    maxHeight: "200px",
    overflowY: "scroll",
    scrollBehavior: "smooth",
  }),
  ...GeneralStyles,
};

const styles = {
  form: {
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: "transparent",
      width: "100%",
      height: "2rem",
      minHeight: "auto",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "0px solid",
      borderBottom: "1px solid #aba49c",
      borderRadius: 0,
      cursor: "default",
      boxShadow: "none",
      transition: "none",
      outlineStyle: isFocused && "dashed !important",
      outlineWidth: isFocused && "1px !important",
      outlineOffset: isFocused && "1px !important",
      outlineColor: isFocused && "#76695D !important",

      "&:hover": {
        borderColor: "none",
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",

      "& path": {
        stroke: "#76695D",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#76695D",
      height: "23px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.selectProps.placeholderColor ? "#76695D" : provided.color,
    }),
    menu: () => ({
      font: "inherit",
      backgroundColor: "#fff",
      borderTop: 0,
      border: "1px solid #DFD8CF",
      boxSizing: "border-box",
      marginTop: "-1px",
      label: "menu",
      position: "absolute",
      top: "100%",
      width: "100%",
      zIndex: 20,
    }),
    option: (provided, { isDisabled, isSelected, isFocused }) => ({
      ...provided,
      height: "32px",
      display: "flex",
      alignItems: "center",
      backgroundColor: isFocused ? "#968d84" : isSelected && "#2b2b2b",
      color: isFocused || isSelected ? "#ffffff" : "#76695D",

      "&:hover": {
        backgroundColor: "#968d84",
        color: "#ffffff",
      },
    }),
    ...GeneralStyles,
    ...ValueContainerStyles,
  },
  return: {
    control: () => ({
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "0px solid",
      borderBottom: "1px solid #aba49c",
      borderRadius: 0,
      transition: "all 0.2s ease",
      padding: "0 0 4px 0",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "23px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#2b2b2b",
      marginLeft: 0,
    }),
    menu: () => ({
      font: "inherit",
      backgroundColor: "#fff",
      borderTop: 0,
      border: "1px solid #DFD8CF",
      boxSizing: "border-box",
      marginTop: "-1px",
      label: "menu",
      position: "absolute",
      top: "100%",
      width: "100%",
      zIndex: 20,
    }),
    ...GeneralStyles,
    ...ValueContainerStyles,
  },
  cart: {
    control: () => ({
      font: "inherit",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "1px solid #2b2b2b",
      borderRadius: 0,
      transition: "all 0.2s ease",
      padding: "4px 10px",
      height: "2.125rem",
    }),
    menu: () => ({
      font: "inherit",
      backgroundColor: "#fff",
      borderTop: 0,
      border: "1px solid #DFD8CF",
      boxSizing: "border-box",
      marginTop: "-1px",
      label: "menu",
      position: "absolute",
      top: "100%",
      width: "100%",
      zIndex: 20,
    }),
    ...GeneralStyles,
    ...ValueContainerStyles,
  },
  upsell: {
    placeholder: (provided) => ({
      ...provided,
      color: "#2b2b2b",
    }),
    control: () => ({
      font: "inherit",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignContent: "center",
      border: "1px solid #968D84",
      borderRadius: 0,
      transition: "all 0.2s ease",
      padding: "0 10px",
      height: "1.5rem",
      fontSize: 14,
      lineHeight: 1,
      color: "#2B2B2B",
    }),
    menu: () => ({
      font: "inherit",
      fontSize: 15,
      backgroundColor: "#fff",
      borderTop: 0,
      border: "1px solid #968D84",
      boxSizing: "border-box",
      marginTop: "-1px",
      label: "menu",
      position: "absolute",
      top: "100%",
      width: "100%",
      zIndex: 20,
    }),
    ...GeneralStyles,
    ...ValueContainerStyles,
  },
  addToCartFooter: {
    control: () => ({
      font: "inherit",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "1px solid #2b2b2b",
      borderRadius: 0,
      transition: "all 0.2s ease",
      padding: "2px 10px",
      height: "56px",
      whiteSpace: "break-spaces",
    }),
    singleValue: (styles) => ({
      ...styles,
      whiteSpace: "break-spaces",
    }),
    menu: () => ({
      font: "inherit",
      backgroundColor: "#fff",
      borderTop: 0,
      border: "1px solid #DFD8CF",
      boxSizing: "border-box",
      label: "menu",
      position: "absolute",
      bottom: "100%",
      width: "100%",
      zIndex: 20,
      fontSize: 15,
    }),
    ...GeneralStyles,
    ...ValueContainerStyles,
  },
  sizes: productStyles,
  denominations: productStyles,
};

const FormDropdown = withDropdown(
  ({
    className,
    dataTestId,
    defaultValue,
    error,
    handleChange,
    handleMenuClose,
    id,
    isSearchable,
    label,
    layout,
    name,
    optional,
    options,
    placeholder,
    placeholderColor,
    required,
    selectorRef,
    value,
  }) => (
    <div className={className}>
      <FormLabel
        label={label}
        required={required}
        id={id}
        optional={optional}
      />
      <StyledSelect
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          SingleValue: CustomSingleValue,
        }}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        formatOptionLabel={layout === "sizes" ? FormatOptionLabel : undefined}
        id={id}
        isSearchable={isSearchable}
        name={name}
        onChange={handleChange}
        onMenuClose={handleMenuClose}
        options={options}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        ref={selectorRef}
        required={required}
        styles={
          error === "true" && styles[`${layout}Error`]
            ? styles[`${layout}Error`]
            : styles[layout]
        }
        value={value}
      />
      {required && (
        <HiddenInput tabIndex={-1} autoComplete="off" value={value} required />
      )}
    </div>
  ),
);

export default FormDropdown;
