import { ResolvedLink } from "@ts/components";
import { useApp } from "./useApp";
import { useCore } from "./useCore";

export const useRoutes = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp();
  const {
    helpers: { isBrowser },
  } = useCore();

  const findRoute = (type) => {
    if (
      type?.toLowerCase() === "pagehomepage" ||
      type?.toLowerCase() === "homepage"
    )
      return {
        type: "homepage",
        url: routes[`HOMEPAGE`],
      };
    const route = Object.entries(routes)?.find(([key]) =>
      type
        ?.toLowerCase()
        ?.replace(`pageaccount`, ``)
        ?.includes(key?.toLowerCase()),
    ) || [`page`, routes[`page`]];

    return {
      type: route[0]?.toLowerCase(),
      url: route[1],
    };
  };

  const linkResolver = (item): ResolvedLink => {
    const document = item?.document || item;
    const type = document?._type || document?.__typename || document?.type;

    if (!document) return {};
    if (type === `navigationSub`) return { url: null, type: `navigationSub` };
    if (type === `link`)
      return {
        url: document?.link,
        external: document?.external,
        title: document?.title,
      };

    const route = findRoute(type);

    return {
      title: item?.title || document?.title || null,
      type: route?.type || null,
      url: routeResolver({
        item: document[route?.type] || document,
        type: route?.type,
      }),
    };
  };

  const routeResolver = ({ item, type }: SanityResolvedRoute): string => {
    const dynamicTypes = [
      `product`,
      `collection`,
      `flexible`,
      `page`,
      `article`,
    ];
    const itemType = type ?? item?.type ?? item?._type;
    const nonLocalisedId = item?._id?.split("__")?.[0];
    const normalisedId = nonLocalisedId
      ?.replace(`drafts.`, ``)
      ?.replace(`page`, ``)
      ?.toLowerCase();
    const handle =
      item?.meta?.metaCanonicalURL?.current ??
      item?.slug?.current ??
      item?.shopify?.shopifyHandle ??
      item?.handle ??
      normalisedId;
    const route = findRoute(itemType);

    return `${route?.url}${handle && dynamicTypes?.includes(route?.type?.toLowerCase()) ? `/${handle}` : ``}`;
  };

  const setUrlParameter = (key, value, uri = window?.location?.pathname) => {
    const regex = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf("?") !== -1 ? "&" : "?";

    return uri?.match(regex)
      ? uri.replace(regex, "$1" + key + "=" + value + "$2")
      : uri + separator + key + "=" + value;
  };

  const getUrlParameter = (
    name,
    location = (isBrowser && window?.location) || null,
  ) => {
    const params = new URLSearchParams(location?.search);

    return params.get(name);
  };

  const replaceUrl = (url: string, push: boolean = false) => {
    if (!isBrowser) {
      return;
    }

    if (push) {
      window.history.pushState(
        { ...window.history.state },
        window.document.title,
        url,
      );
    } else {
      window.history.replaceState(
        { ...window.history.state, as: url, url },
        window.document.title,
        url,
      );
    }
  };

  const getUrlFragments = (
    location = (isBrowser && window?.location) || null,
  ) => {
    return {
      search: location?.search?.replace("?", ""),
      path: location?.pathname,
    };
  };

  const getCurrentUrl = () => {
    return isBrowser ? window?.location?.href : undefined;
  };

  const stripTrailingSlash = (url: string) => {
    return url?.replace(/\/$/, "");
  };

  return {
    findRoute,
    getUrlParameter,
    linkResolver,
    replaceUrl,
    routeResolver,
    setUrlParameter,
    getUrlFragments,
    getCurrentUrl,
    stripTrailingSlash,
  };
};

type SanityResolvedRoute = {
  item?: {
    _id?: string;
    handle?: string;
    type?: string;
    _type?: string;
    meta?: {
      metaCanonicalURL?: {
        current: string;
      };
    };
    slug?: {
      current?: string;
    };
    shopify?: {
      shopifyHandle?: string;
    };
  };
  type?: string;
};
