/**
 * This is a fork of the Headless core's helper function. The sanityContent
 * helper function is modified to work with Bared's Sanity configuration for
 * flexible pages.
 */

import { ContentDivider } from "@components/ContentDivider/ContentDivider";
import { Link } from "@components/Link/Link";
import BlockContent from "@sanity/block-content-to-react";
import parse from "html-react-parser";
import React from "react";
import ContentButton from "../components/ContentButton/ContentButton";
import { ContentColouredText } from "../components/ContentStyledText/ContentStyledText";

import { CustomImage } from "@bared/ui";

export const renderCode = (html) => {
  const elementId = Math.random().toString(36).substring(9);

  return parse(`${html}`, {
    replace: (domNode) => {
      if (
        "attribs" in domNode &&
        typeof document !== "undefined" &&
        domNode.type === `script`
      ) {
        let script = document.createElement(`script`);
        if (domNode.attribs.src) script.src = domNode.attribs.src;
        if (domNode.children.length)
          script.innerHTML = domNode.children[0]["data"];
        script.id = `code-${elementId}`;
        script.className = `code-${elementId}`;
        script.type = `text/javascript`;
        if (domNode.attribs.src) script.async = true;
        if (domNode.attribs.src) script.defer = true;
        document.body.prepend(script);
        return <React.Fragment key={elementId} />;
      }
      return domNode;
    },
  });
};

const typeToUrlPrefix = {
  article: "/articles",
  collection: "/collections",
  product: "/products",
};

const internalLinkSerializer = ({ children, mark }) => {
  let targetUrl = mark?.document?.link || "";
  if (!targetUrl && mark?.document) {
    const documentType = mark?.document?.document?._type || "";
    const slug =
      mark?.document?.document?.slug?.current ||
      mark?.document?.document?.shopify?.shopifyHandle ||
      "";
    const urlPrefix = typeToUrlPrefix[documentType] || "";
    targetUrl = `${urlPrefix}/${slug}`;
  }
  return (
    <Link to={targetUrl} title={mark?.document?.title}>
      {children}
    </Link>
  );
};

const linkSerializer = ({ children, mark }) => (
  <a
    href={mark?.link?.link}
    title={mark?.link?.title}
    target={mark?.link?.external ? "_blank" : ""}
  >
    {children}
  </a>
);

const translationsSerializer =
  (locale) =>
  ({ children, mark }) => {
    const translationsLocale = locale?.replace(/-/g, "");
    return mark?.[translationsLocale] ? (
      <>{mark[translationsLocale]}</>
    ) : (
      <>{children}</>
    );
  };

const genSerializers = (locale) => {
  return {
    marks: {
      internal: internalLinkSerializer,
      document: internalLinkSerializer,
      link: linkSerializer,
      color: ({ children, mark }) => {
        return (
          <ContentColouredText hex={mark?.hex}>{children}</ContentColouredText>
        );
      },
      translations: translationsSerializer(locale),
    },
    types: {
      code: ({ node }) => {
        renderCode(node.code);
        return <div dangerouslySetInnerHTML={{ __html: node.code }} />;
      },
      image: ({ node }) => <img src={node?.asset?.url} />,
      /**
       * For customImage nodes which have an asset URL, these have come from the Gatsby
       * GraphQL utils. If they do not have an asset URL, they can be rendered using
       * the new CustomImage component.
       *
       * This is a stop gap solution until this entire component can be replaced with the
       * new SanityRichText component.
       */
      customImage: ({ node }) => {
        if (!node || !node.asset) return null;

        const { imagelink: imageLinks, ...customImage } = node;

        const image = customImage.asset.url ? (
          <img
            alt={customImage.alt}
            src={customImage.asset.url}
            width={customImage.displayWidth}
          />
        ) : (
          <CustomImage data={customImage} />
        );

        if (imageLinks) {
          const imageLink = imageLinks[0];
          if (!imageLink) return image;

          if (imageLink._type === "internal") {
            return internalLinkSerializer({
              children: image,
              mark: {
                document: imageLink,
              },
            });
          }

          if (imageLink._type === "link") {
            return linkSerializer({
              children: image,
              mark: {
                link: imageLink,
              },
            });
          }
        }

        return image;
      },
      contentDivider: ({ node }) => {
        return <ContentDivider {...node} />;
      },
      link: ({ node }) => {
        return <ContentButton {...node} />;
      },
    },
  };
};

export const sanityContent = (content, locale?) => {
  return content ? (
    <BlockContent
      className={"rte"}
      blocks={content}
      serializers={genSerializers(locale)}
    />
  ) : (
    content
  );
};
