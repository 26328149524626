import { useApp } from "@hooks/useApp";
import { useCheckoutContext } from "@hooks/useCheckout";
import { ComponentProps } from "@ts/components";
import React, { FC } from "react";

export type SearchResultsGiftCardInputProps = ComponentProps & {
  tags: string[];
};

export type SearchResultsGiftCardOutputProps = ComponentProps & {
  price: string;
};

export const withSearchResultsGiftCardPrice =
  (Component: FC<SearchResultsGiftCardOutputProps>) =>
  ({
    name = "SearchResultsGiftCardPrice",
    tags,
    className,
  }: SearchResultsGiftCardInputProps) => {
    const { checkout } = useCheckoutContext();
    const { currencyCode } = checkout || {};

    const {
      config: {
        settings: { keys },
      },
    } = useApp();

    const maxPrice = tags
      ?.find((tag) => tag?.includes(keys?.tag_gift_card_price_max))
      ?.split(":")[1];
    const minPrice = tags
      ?.find((tag) => tag?.includes(keys?.tag_gift_card_price_min))
      ?.split(":")[1];

    const getFormattedPrice = () => {
      if (!maxPrice || !minPrice) {
        return "";
      }

      if (currencyCode !== "AUD") {
        // Gift cards cannot be sold with market pricing
        // See "Fixed pricing" section here: https://help.shopify.com/en/manual/markets/pricing/gift-cards
        return `${currencyCode}$${minPrice} - ${currencyCode}$${maxPrice}`;
      }

      return `$${minPrice} - $${maxPrice}`;
    };

    Component.displayName = name;
    return <Component price={getFormattedPrice()} className={className} />;
  };
