import { ProductContext } from "@providers/localised/product";
import { useContext } from "react";

export const useProductContext = () => {
  const productContext = useContext<ProductContext>(ProductContext);
  return { ...productContext };
};

export const useProduct = () => {
  const getCartAffixesFromTags = (tags?: string[]) => {
    const prefixRegex = /cart:prefix:(.*)/g;
    const suffixRegex = /cart:suffix:(.*)/g;
    const tagWithPrefix = tags?.find((tag) => tag.match(prefixRegex));
    const tagWithSuffix = tags?.find((tag) => tag.match(suffixRegex));
    const prefix = tagWithPrefix?.replace(prefixRegex, "$1");
    const suffix = tagWithSuffix?.replace(suffixRegex, "$1");

    return {
      prefix,
      suffix,
    };
  };

  return {
    getCartAffixesFromTags,
  };
};
