import { type SettingSizeAlias, type SettingSizeGuide } from "@ts/sanity";
import { graphql, useStaticQuery } from "gatsby";
import { useLocalisation } from "./useLocalisation";

export type UseSizes = Partial<SettingSizeGuide> & {
  alias: SizeAlias;
  downloadableSizeGuideUrl: string;
};

type SizeAlias = {
  sizeAlias: {
    [key: string]: string;
  };
  activeAlias: {
    [key: string]: string[];
  };
};

export const useSizes = (): UseSizes => {
  const { findNodeByLocale } = useLocalisation();
  const data = useStaticQuery<{
    sizes: SettingSizeGuide[];
    rawAlias: SettingSizeAlias;
  }>(graphql`
    query SANITY_SETTINGS_SIZES {
      sizes: allSanitySettingSizeGuide {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            summaryMens: _rawSummaryMens(resolveReferences: { maxDepth: 2 })
            summaryWomens: _rawSummaryWomens(resolveReferences: { maxDepth: 2 })
            womenFromTitle
            womenToTitle
            menFromTitle
            menToTitle
            unitTitle
            inchTitle
            women {
              _key
              us_au
              bared
              cm
              inch
            }
            men {
              _key
              us_au
              bared
              cm
              inch
            }
            content: _rawContent(resolveReferences: { maxDepth: 8 })
            downloadableSizeGuide {
              asset {
                url
              }
            }
            downloadableSizeGuideCTAText
          }
        }
      }
      rawAlias: sanitySettingSizeAlias {
        sizeAlias {
          alias
          size
        }
      }
    }
  `);

  const { sizes, rawAlias } = data;
  const localSizeGuide = findNodeByLocale<SettingSizeGuide>(sizes);
  const { downloadableSizeGuide, ...rest } = localSizeGuide;

  const downloadableSizeGuideUrl = downloadableSizeGuide?.asset?.url;
  const alias = rawAlias?.sizeAlias?.reduce(
    (result, item) => {
      if (item.size) {
        result.activeAlias[item.size] = !result.activeAlias[item.size]
          ? [...item.alias]
          : [...result.activeAlias[item.size], ...item.alias];
      }

      if (item?.alias?.length) {
        for (const value of item.alias) {
          result.sizeAlias[value] = item.size;
        }
      }

      return result;
    },
    { sizeAlias: {}, activeAlias: {} },
  );

  return {
    alias,
    downloadableSizeGuideUrl,
    ...rest,
  };
};
