import React, { lazy, Suspense } from "react";
import { IconProps } from "./Icon/Icon";

const IconComponent = lazy(() =>
  import(/* webpackChunkName: "icons" */ "./Icon/Icon").then((module) => ({
    default: module.Icon,
  })),
);

export const Icon = (props: IconProps) => {
  return (
    <Suspense fallback={<></>}>
      <IconComponent {...props} />
    </Suspense>
  );
};
