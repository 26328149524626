import { useSettings } from "@hooks/useSettings";
import { globalHistory } from "@reach/router";
import React, {
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";
import TagManager from "react-gtm-module";
import VisibilitySensor from "react-visibility-sensor";

export type TrackingContextProps = {
  referrer?: {
    current?: string;
    previous?: string;
  };
  gtmTrack?: (event: any) => void;
  setTracked?: Dispatch<SetStateAction<boolean>>;
  tracked?: boolean;
  VisibilitySensor?: typeof VisibilitySensor;
};

export const TrackingContext = React.createContext<TrackingContextProps>({});

export const TrackingProvider = ({ children }) => {
  const [tracked, setTracked] = useState(false);
  const [referrer, setReferrer] = useState({
    current: null,
    previous: typeof document !== "undefined" ? document.referrer : null,
  });
  const { tracking } = useSettings();

  const gtmTrack = (event: any) => {
    TagManager.dataLayer(event);
  };

  const values = useMemo(
    () => ({
      gtmTrack,
      tracked,
      setTracked,
      VisibilitySensor,
      referrer,
    }),
    [gtmTrack, tracked, setTracked, VisibilitySensor, referrer],
  );

  useEffect(() => {
    if (tracking?.googleTagManagerId) {
      TagManager.initialize({
        gtmId: tracking.googleTagManagerId,
        dataLayerName: "GA4",
      });

      setTracked(true);
    }
  }, [tracking]);

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      setReferrer((prevState) => ({
        current: location.href,
        previous: prevState.current,
      }));
    });
  }, [setReferrer]);

  return (
    <TrackingContext.Provider value={values}>
      {children}
    </TrackingContext.Provider>
  );
};
