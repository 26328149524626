import React, { memo, useMemo, type FC } from "react";
import {
  useNavigation,
  type NormalisedNavigation,
} from "../../hooks/useNavigation";
import { type ComponentProps } from "../../types/components";

export type FooterTermsInputProps = ComponentProps;
export type FooterTermsOutputProps = {
  items: NormalisedNavigation[];
};

export const withFooterTerms = (Component: FC<FooterTermsOutputProps>) =>
  memo(({ name = "FooterTerms" }: FooterTermsInputProps) => {
    const {
      terms: { items },
    } = useNavigation();

    Component.displayName = name;
    return useMemo(() => <Component items={items} />, [items]);
  });
