import { VisuallyHidden } from "@components/Accessibility/VisuallyHidden/VisuallyHidden";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import { ComponentProps } from "@ts/components";
import React from "react";
import tw from "twin.macro";

export type FormLabelProps = ComponentProps & {
  className?: string;
  field?: string;
  id: string;
  label: string;
  optional?: boolean;
  required: boolean;
  requiredError?: boolean;
};

const Label = styledWithInvalidProps("label")(({ field, requiredError }) => [
  tw`pb-mini`,
  requiredError ? tw`text-warning` : null,
  field === `checkbox` ? tw`pb-zero pl-mini` : null,
]);
const LabelWrapper = tw.div`
  flex 
`;
const Required = tw.div`
  text-warning pl-micro
`;
const Optional = tw.div`
  pl-micro
`;

const FormLabel = ({
  className,
  field,
  id,
  label,
  optional,
  required,
  requiredError,
}: FormLabelProps) => {
  return (
    <div className={className}>
      {label && !required && !optional && (
        <LabelWrapper>
          <Label field={field} htmlFor={id}>
            {label}
          </Label>
        </LabelWrapper>
      )}
      {label && required && (
        <LabelWrapper>
          <Label field={field} htmlFor={id} requiredError={requiredError}>
            {label}
          </Label>
          <Required aria-hidden="true">*</Required>
          <VisuallyHidden>required</VisuallyHidden>
        </LabelWrapper>
      )}
      {label && optional && (
        <LabelWrapper>
          <Label field={field} htmlFor={id}>
            {label}
          </Label>
          <Optional>(Optional)</Optional>
        </LabelWrapper>
      )}
    </div>
  );
};
export default FormLabel;
